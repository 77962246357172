/*
* Ajax Form that valaidates then makes a ajax call
* Author: Juan Rocha
*/

; (function ($, window, document, undefined) {

    var pluginName = 'ajaxForm',
        defaults = {
            onBefore: function () { },
            onComplete: function () { },
            onSuccess: function () { },
            onFail: function () { },
            rules: {},
            messages: {},
            $processIcon: $('div.ajax-process-icon')
        };

    function AJAXForm(element, options) {
        this.options = $.extend({}, defaults, options);
        this.element = element;
        this.$element = $(element);

        this.url = this.$element.attr('action');
        this.method = this.$element.attr('method');
        this.validate();
        this.init();
    }

    function htmlEscape(s) {
        return s.replace(/%3C/g, '').replace(/%26#/g, '');
    }

    AJAXForm.prototype.init = function () {
        var self = this;
        var validatorOptions = {
            rules: self.options.rules,
            messages: self.options.messages,
            onsubmit: true,
            onfocusout: false,
            onkeyup: false,
            onclick: false,
            focusInvalid: false,
            submitHandler: function (form, event) {
                // form is valid
                try {
                    //grecaptcha.execute();
                    self.ajax();
                } catch (e) {                    
                    //console.error('recaptcha failed');
                }
                event.preventDefault()
            }
        };

        if(self.options.ignore) {
            validatorOptions['ignore'] = self.options.ignore;
        }

        self.$element.validate(validatorOptions);
    };

    AJAXForm.prototype.ajax = function () {
        var self = this;
        var request = $.ajax({
            method: self.method,
            url: self.url,
            data: htmlEscape(self.$element.serialize()),
            beforeSend: function () {
                self.options.$processIcon.fadeIn();
                self.options.onBefore.call(this, self.$element);
            }
        });

        request.always(function (data) {
            self.options.$processIcon.fadeOut();
            self.options.onComplete.call(this, self.$element);
        });

        request.done(function (data) {

            if (!data || typeof(data) === 'undefined' || !data.processed || typeof(data.processed) === 'undefined')
            {
                // probably an HTML response from atlas
                self.options.onFail.call(this, self.$element);
            }
            else if (data.processed) {
                self.options.onSuccess.call(this, self.$element);
            }
        });

        request.fail(function (jqXHR, status) {
            self.options.onFail.call(this, self.$element);
        });
    };
    
    AJAXForm.prototype.validate = function () {
        if (this.url === null || this.url === '') throw "Error: Invalid URL";
        if (this.method === null || this.method === '') throw "Error: Invalid URL";
        if (typeof this.options.onSuccess !== 'function') throw "Error: Invalid onSuccess";
        if (typeof this.options.onBefore !== 'function') throw "Error: Invalid onBefore";
        if (typeof this.options.onComplete !== 'function') throw "Error: Invalid onComplete";
        if (typeof this.options.rules !== 'object') throw "Error: Invalid rules";
        if (typeof this.options.messages !== 'object') throw "Error: Invalid messages";
        return true;
    };

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName,
                    new AJAXForm(this, options));
            }
        });
    }
})(jQuery, window, document);