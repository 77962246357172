// Do person search from querystring
if (getUrlParameterByName("searchtype") && getUrlParameterByName("searchtype") === "person") {
    if (getUrlParameterByName("select-region")) {
        $('#select-region').val(getUrlParameterByName("select-region"));
    }

    if (getUrlParameterByName("cname")) {
        $('#cname').val(getUrlParameterByName("cname"));
    }

    $('#profilesearch').click();
}

$(function() {
    profileSearch()
    $('#profilesearch').click(profileSearch);
});

function profileSearch() {
    if ($("#select-region").length > 0) {
        var regionfilter = $('#select-region').val().toLowerCase();
        $('.m15-profile').each(function (i) {
            var showProfile = true;

            // Region check
            if (regionfilter != undefined && regionfilter != "") {
                if ($(this).data("region").toLowerCase().indexOf(regionfilter) === -1) {
                    showProfile = false;
                }
            }

            if (showProfile)
                $(this).show();
            else
                $(this).hide();
        });
    }
}