function getUrlParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function eventContentEllipsis() {
    $('.event-list-metacontent p').ellipsis({
        lines: 2,             // force ellipsis after a certain number of lines. Default is 'auto'
        ellipClass: 'ellip',  // class used for ellipsis wrapper and to namespace ellip line
        responsive: true      // set to true if you want ellipsis to update on window resize. Default is false
    });
}

$(function () {
    eventContentEllipsis();

    $('#KJETitle').before($('#calculatorTitle'));
    $('#calculatorTitle').removeClass('d-none');

    if (document.documentElement.clientWidth < 600) {
        $('.calculator-nav-item').each(function () {
            if ($(this).hasClass('active')) {
                $('.calculator-mobile-menu').css('display', 'block');
                $('.calculator-tabs').css('display', 'none');
            }
        });
    } else if (document.documentElement.clientWidth > 1023) {
        // focus on userid at load
        //$("#UserID", ".toolbar-login-cell").focus();
    }

    $(document).on('click', '#KJEViewReport', function () {
        if ($(this).val() === 'Hide Report') {
            $('#KJECalculatorScreenSize').removeClass('calculator-report-heading');
        } else {
            $('#KJECalculatorScreenSize').addClass('calculator-report-heading');
        }
    });

    $(document).on('click', function (event) {
        var $target = $(event.target);
        if($target.not('.KJEGuiDefnPopup').length && $target.not('.KJEDefinitionIcon').length){
            event.stopPropagation();
            var $popup = $('.KJEGuiDefnPopup[aria-hidden="false"]');
            var $parentInput = $popup.closest('.KJEInputContainer');
            var $definitionIcon = $parentInput.find('.KJEDefinitionIcon');
            $definitionIcon.trigger('click');
            $definitionIcon.attr('aria-expanded', 'false');
        }
    });

    $('.search-icon').on("click", function (e) {
        e.preventDefault();
        $loginSearchForm = $(e.target).closest('#login-search-form');
        $loginSearchForm.length && $loginSearchForm.trigger('submit');
    });

    $('.search-icon').on('keydown', function(e) {
        if(e.key == 'Enter' || e.key == ' ') {
          e.preventDefault();
          $(this).click();
        }
      })

    $('.nav-menu .nav-item a').on('click', function (e) {
        var target = $(e.target).attr('href');
        if (!target || target == '#') {
            e.preventDefault();
        }
    })
    //adding class to proxy voting form  page 
    if ($('.proxy-voting-form').length > 0) {
        $('.primary').addClass('primary-proxy');
        $('.page-content').addClass('primary-proxy');
    }

    // Search Functionality
    $(".btn-search").on("click", function (e) {
        e.preventDefault();
        $('.js-mobile-login').removeClass('mobile-login-open').attr('aria-expanded', 'false');

        $('#nav-icon3').removeClass('mobile-open open').attr('aria-selected', 'false');
        $('#mobile-menu').slideUp().attr('aria-hidden', 'true');

        $(".search-text").addClass("expanded");
        $(".toolbar").addClass("site-search-expanded");
        $(".close-btn").show();
        $('.search-icon').show();
        $('.search-icon').addClass('drag');
        $('.searchbox input[type=submit]').addClass('expand-search');
        $(this).css("visibility", "hidden");
    })

    $(document).on('click', '.close-btn', function (e) {
        e.preventDefault();
        $('.sticky-form').remove();
        $('body').removeClass('opacity noscroll');
        $('.sticky-form-mobile').hide();
        $('.sticky-form-mobile .search-icon').hide();
        $(".sticky-form-mobile .close-btn").hide();

    })
    $(document).on('click', '.sticky-form-mobile .close-btn', function (e) {
        e.preventDefault();
        $('body').addClass('opacity');
        $('.mobile-menu-list').show();
        $('.mobile-search').show();
        $('#mobile-menu').removeClass('menu-height');

    })

    $(".close-btn").on("click", function (e) {
        e.preventDefault();
        $(".search-text").removeClass("expanded").val("").removeAttr("style");
        $(".toolbar").removeClass("site-search-expanded");
        $(".close-btn").hide();
        $('.search-icon').hide();
        $('.search-icon').removeClass('drag');
        $(".btn-search").show().css("visibility", "visible");
        $('.sticky-search').show();
        $('body').removeClass('opacity noscroll');
        $('.searchbox input[type=submit]').removeClass('expand-search');

    })

    $('.close-btn').on("click", function (e) {
        e.preventDefault();
        $('.sticky-search').show();
        $('body').removeClass('opacity noscroll');
        $('.sticky-form').hide();
    })

    $(document).on('mouseenter', '.header-sticky ul.nav-menu .nav-item > a:not(.sticky-search)', function () {
        // Hide search
        $('.sticky-form').remove();
        // Hide login
        $('.js-mobile-login').removeClass('mobile-login-open').attr('aria-selected', 'false');
        // make body scrollable
        $('body').removeClass('noscroll');
    });

    //validation for OLB 

    $("form[name='signon-form']").validate({
        rules: {
            UserName: {
                required: true
            },
            Password: {
                required: true
            }
        },
        messages: {
            UserName: {
                required: "Please enter your User ID"
            },
            Password: {
                required: "Please enter your Password"
            },
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    $("form[name='signon-form-mobile']").validate({
        rules: {
            UserName: {
                required: true
            },
            Password: {
                required: true
            }
        },
        messages: {
            UserName: {
                required: "Please enter your User ID"
            },
            Password: {
                required: "Please enter your Password"
            },
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    $('.tab-mobile-menu .dropdown a').click(function (e) {
        $('.tab-mobile-menu .dropdown-item').removeClass('active show')
        $("#tabDropdownMenu").text($(this).text());
    })

    // auto jump selector for mobile
    $(".mobile-link-selector").on("change", function () {
        var url = $(":selected", $(this)).data("url");
        if (url) {
            window.location = url;
        }
        return false;
    });

      // js cutoff dates
      $(window).scroll(function () {
        if ($(window).width() >= 1024) {
            var scroll = $(window).scrollTop();
            if (scroll > 50) {
                $(".header-navigation").addClass("header-sticky");
                //$(".skip-links .hidden-above-lg:last-child").show();
                $('body').addClass('sticky');
            } else {
                $(".header-navigation").removeClass("header-sticky");
                //$(".skip-links .hidden-above-lg:last-child").hide();
                $('body').removeClass('sticky');
                $('form.sticky-form ,form.sticky-form .close-btn, form.sticky-form .search-icon').hide();
            }
        }
    });

    var slideIndex = $('.calculator-tabs .calculator-nav-item').index($('.calculator-tabs .calculator-nav-item.active'));
    $('.tab-slider-nav').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 300,
        focusOnSelect: true,
        centerMode: true,
        centerPadding: 0,
        initialSlide: slideIndex != -1 ? slideIndex : 0
    });
    function unSlick() {
        $('.tab-slider-nav').slick('resize');
        var viewportWidth = jQuery(window).width();

        if (viewportWidth < 768) {
            $('.tab-slider-nav').slick('unslick');
        }
    }
    unSlick();
    jQuery(window).on('resize', function () {
        unSlick();
    });

    if ($('.image-link-blocks-slider').length) {
        $('.image-link-blocks-slider').slick({
            infinite: true,
            variableWidth: true,
            slidesToScroll: 1,
            speed: 600,
            focusOnSelect: true,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        centerMode: true
                    }
                }
            ]
        });
    }

    $(document).ready(function () {
        $(".modal-external-redirect-buttons .btn-secondary").on("click", function () {
            $.magnificPopup.close();
        });
        $(".external-link-extended").magnificPopup({
            type: "inline",
            midClick: true,
            callbacks: {
                open: function () {
                    $(".modal-external-redirect").attr("tabindex", 0).focus();
                    $("html, body, main, .mfp-preloader, .skip-link").attr({
                        tabindex: -1,
                    });
                },
                elementParse: function (item) {
                    var firedElement = item.el;
                    if ($(firedElement)) {
                        var externalLink = $(firedElement).data("href");
                        $("#modal-external-redirect-popup .btn-primary").attr("href", externalLink);
                    }
                },
                close: function () {
                    $("html, body, main, .mfp-preloader, .skip-link").attr({
                        tabindex: "",
                    });
                },
            },
        });
        $("#find-medicare-check").change(function () {
            if (this.checked) {
                $("#find-medicare-button").removeClass("disabled");
            }
            else {
                $("#find-medicare-button").addClass("disabled");
            }
        });
        $("#find-medicare-zip").on("input", function () {
            if ($("#find-medicare-button")) {
                var original = $("#find-medicare-button").data("original");
                if (original && this.value) {
                    $("#find-medicare-button").data("href", original + this.value);
                }
            }
        });
        $("#covr-button").click(function () {
            var original = $(this).data("original");
            original = original.replace("{dob}", $("#covr-dob").val());
            original = original.replace("{gender}", document.querySelector('input[name="covr-gender"]:checked').value);
            original = original.replace("{tobacco}", document.querySelector('input[name="covr-tobacco"]:checked').value);
            original = original.replace("{height}", $("#covr-height").val());
            $(this).data("href", original);
        });
        $("#covr-check").change(function () {
            if (this.checked) {
                $("#covr-button").removeClass("disabled");
            }
            else {
                $("#covr-button").addClass("disabled");
            }
        });

        $("a[data-goal],button[data-goal]").on("click", function(){
            navigator.sendBeacon("/Events/TrackedLinkGoalHandler.ashx?gid=" + $(this).data("goal"));
        });

        $(document).on('click', '.btn-compare-card', function () {
            $('.u2021-toggleContainer .comparison-product-toggle').each(function () {
                if (!this.checked) {
                    $(this).click();
                }
            });
            setTimeout(function () {
                $('#u2021-compareBtn.u2021-compare-cards-btn').click();
            }, 200);
        });
    });
});    