
var searchResultsPagination = (function() {
    var fn = {};

    if (!Object.create) {
        Object.create = function(prototype) {
            var obj = function() {
                return undefined;
            };
            obj.prototype = prototype;
            return new obj;
        };
    }

    fn = {
        init: function() {
            var self = this;

            self.pageNumber = 1;
            self.currentPageNumber = 1;
            self.loadingResults = false;
            self.APIUrl = '/api/SearchApi/GetSearchResults';

            $(document).on('click', '.search-result-actions .btn-result-prev', function(e) {
                self.pageNumber = self.currentPageNumber - 1;
                var context = e.target.getAttribute('data-context');
                self.context = context;
                self.getSearchResults();
                e.stopPropagation();
                return false;
            });

            $(document).on('click', '.search-result-actions .btn-result-next', function(e) {
                self.pageNumber = self.currentPageNumber + 1;
                var context = e.target.getAttribute('data-context');
                self.context = context;
                self.getSearchResults();
                e.stopPropagation();
                return false;
            });
        },

        setUrlParams: function() {
            var self = this;

            self.urlParams = {};
            self.urlParams.keyword = $('#refine-search').val();
            self.urlParams.pageNum = self.pageNumber;
            self.urlParams.pageSize = 10;
            if(self.context) {
                self.urlParams.context = self.context;
            }
        },

        getSearchResults: function() {
            var self = this;

            if (self.pageNumber) {
                self.loadingResults = true;

                self.setUrlParams();
                $('.search-container .loading-container').show();
                $.ajax({
                    method: 'GET',
                    url : self.APIUrl,
                    data: self.urlParams,
                    caches: false,
                    success: function(data) {
                        if (data.Success) {
                            self.currentPageNumber = self.pageNumber;
                            self.updateMarkup(data.SearchList);
                            if(self.currentPageNumber === 1) {
                                $('.search-result-actions .btn-result-prev').hide();
                            } else {
                                $('.search-result-actions .btn-result-prev').show();
                            }

                            if(!data.isAvailable) {
                                $('.search-result-actions .btn-result-next').hide();
                            } else {
                                $('.search-result-actions .btn-result-next').show();
                            }
                        } else {
                        self.pageNumber = self.currentPageNumber;
                        }
                    },
                    error: function() {
                        self.pageNumber = self.currentPageNumber;
                    },
                    complete: function() {
                        self.loadingResults = false;
                        $('.search-container .loading-container').hide();

                        $('html').animate({
                            scrollTop: $('#sSWResults').offset().top - 200
                        }, 500);            
                    }
                });
            }
        },

        updateMarkup: function(SearchList) {
            var searchResultsMarkup = '';

            for(var i = 0; i < SearchList.length; i++) {
                searchResultsMarkup += '<div class="search-result-item">';
                searchResultsMarkup += '<a class="search-result-link" href="' + SearchList[i].Url + '" target="_blank">' + SearchList[i].Title + '</a>';
                if(SearchList[i].Description) {
                    searchResultsMarkup += '<div class="search-result-description">' + SearchList[i].Description + '</div>';
                }
                searchResultsMarkup += '</div>';
            }

            $(".search-results").html(searchResultsMarkup);
        }
    };

    return {
        init: function() {
            var paginator = Object.create(fn);
            paginator.init();
            return paginator;
        }
    };
})();

$(function() {
    if ($('.search-results').length) {
        var searchResultsPaginator = searchResultsPagination.init();
    }
    var $refineSearch = $("#refine-search");
    if ($refineSearch) {
        $refineSearch.focus();
    }
});
