
MOAForm = (function() {
    var fn = {},
        api = {},
        formType = '',
        formName = '',
        formValidator = '',
        validationRulesConfig = {};

    fn = {
        init: function() {
            validationRulesConfig = {
                global: {
                    submitHandler: function(form, event) {
                        event.preventDefault();
                        fn.submitForm();
                    }
                },
                personalLoan: {
                    rules: {
                        pLoCLoanPurpose: {
                            required: function(element) {
                                return $(element).is(':visible');
                            }
                        },
                        pLoanPurpose: {
                            required: function(element) {
                                return $(element).is(':visible');
                            }
                        },
                        pLoanAmount: {
                            required: true,
                            min: 1,
                            loanAmount: 'loanAmount'
                        },
                        pLoanTerm: {
                            required: function(element) {
                                return $(element).is(':visible');
                            },
                            min: 1,
                            digits: true
                        }
                    },
                    messages: {
                        pLoCLoanPurpose: {
                            required: 'Loan Purpose is required'
                        },
                        pLoanPurpose: {
                            required: 'Loan Purpose is required'
                        },
                        pLoanAmount: {
                            required: 'Loan Amount is required',
                            min: 'Loan Amount should be greater than or equal to 1',
                            loanAmount: 'Loan Amount should be a number with upto 2 decimal precision'
                        },
                        pLoanTerm: {
                            required: 'Loan Term is required',
                            min: 'Loan Term should be greater than or equal to 1',
                            digits: 'Please enter a non-decimal number'
                        }
                    }
                },
                creditCard: {
                    rules: {
                        cCardPurpose: {
                            required: true
                        }
                    },
                    messages: {
                        cCardPurpose: {
                            required: 'Please select a purpose'
                        }
                    }
                },
                vehicleLoan: {
                    rules: {
                        vLoanPurpose: {
                            required: true
                        }
                    },
                    messages: {
                        vLoanPurpose: {
                            required: 'Please select a purpose'
                        }
                    }
                }
            };

            fn.moaLinkHandler();

            fn.loadTiles();

            fn.closeForm();
        },

        loadTiles: function() {
            var self = this;
            var tilesType = getUrlParameterByName('type');

            if(tilesType && tilesType === 'loans') {
                $('.m8__links > div').hide();
                $('.m8__form-section, .m8__form').hide();
                $('.m8__links-section').slideDown();
                $('#credit-card-tile, #personal-loan-tile, #vehicle-loan-tile').slideDown();
                $('.m8__disclaimer').show();
            } else {
                self.loadDefaultForm();
            }
        },

        loadDefaultForm: function() {
            var defaultForm = getUrlParameterByName('type');

            if(defaultForm && $('#' + defaultForm).length) {
                $('.m8__link--moa[data-link-type="' + defaultForm + '"]').trigger('click');
            } else {
                $('.m8__form-section, .m8__form').hide();
                $('.m8__links-section').slideDown();
            }
            $('.m8__disclaimer').show();
        },

        moaLinkHandler: function() {
            $(document).on('click', '.m8__link--moa', function(e) {
                if($(this).attr('href') === '#') {
                    e.preventDefault();
        
                    formType = $(this).attr('data-link-type');
    
                    if(formType) {
                        $('.m8__links-section').slideUp('slow');
                        $('.m8__form--' + formType).show();
                        $('.m8__form-section').slideDown('slow');
            
                        formName = $('.m8__form--' + formType + ' form').attr('name');
                    }
    
                    fn.moaFormHandler();
                }
            });
        },

        moaFormHandler: function() {
            switch(formName) {
                case 'personalLoan':
                    $(document).on('change', '.m8__form--personal-loan input[type="radio"][name="pLineOfCredit"]', function() {

                        if($(this).val() === 'Y') {
                            $('#pLoCLoanPurpose').show();
                            $('#pLoanPurpose').hide().val('');
                            $('.form-group--loan-term').slideUp();
                        } else {
                            $('#pLoCLoanPurpose').hide().val('');
                            $('#pLoanPurpose').show();
                            $('.form-group--loan-term').slideDown();
                        }
                    });

                    $('.m8__form--personal-loan input[type="radio"][name="pLineOfCredit"][value="N"]').trigger('click');

                    fn.addCustomValidations();

                    formValidator = $('form[name="' + formName + '"]').validate($.extend({}, validationRulesConfig.personalLoan, validationRulesConfig.global));

                    break;

                case 'creditCard':
                    formValidator = $('form[name="' + formName + '"]').validate($.extend({}, validationRulesConfig.creditCard, validationRulesConfig.global));

                    break;

                case 'vehicleLoan':
                    formValidator = $('form[name="' + formName + '"]').validate($.extend({}, validationRulesConfig.vehicleLoan, validationRulesConfig.global));

                    break;
            }
        },

        addCustomValidations: function() {
            switch(formName) {
                case 'personalLoan':
                    $.validator.addMethod('loanAmount', function(value, element) {
                        var loanAmountRegex = new RegExp(/^\d{1,12}(\.\d{1,2})?$/);

                        if(loanAmountRegex.test(value)) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                    break;
            }
        },

        submitForm: function() {
            var apiURL = $('form[name="' + formName + '"]').attr('action');

            $('form[name="' + formName + '"]').find('input[type="text"]:visible, select:visible').each(function(key, val) {
                apiURL += '&' + $(val).attr('data-api-attr-name') + '=' + $(val).val();
            });

            $('form[name="' + formName + '"]').find('input[type="radio"]:visible:checked').each(function(key, val) {
                if($(val).attr('data-api-attr-name')) {
                    apiURL += '&' + $(val).attr('data-api-attr-name') + '=' + $(val).val();
                }
            });

            window.open(apiURL);
        },

        closeForm: function() {
            $(document).on('click', '.m8__form__action--back', function() {
                if(formType) {
                    if(document.referrer.indexOf(location.protocol+'//'+location.host) === 0) {
                        window.location = document.referrer;
                        return;
                    }

                    if(formName) {
                        $('form[name="' + formName + '"]').trigger('reset');
                        formValidator.resetForm();
                    }

                    $('.m8__form-section').slideUp('slow');
                    $('.m8__links-section').slideDown('slow');
                    $('.m8__form--' + formType).hide();
        
                    formType = '';
                    formName = '';
                }

                $('html').animate({
                    scrollTop: $('.m8__links-container').offset().top - 50
                });
            });
        }
    }

    api = {
        init: function() {
            return fn.init.apply(this, arguments);
        }
    }
    return api;
})();

$(function() {
    MOAForm.init();
});