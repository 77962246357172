$(function () {
  "use strict";

  var $windowWidth = $(this).width();
  var $windowHeight = $(this).height();

  $(window).on("load resize", function () {
    $windowWidth = $(this).width();
    $windowHeight = $(this).height();

    var wrapperSize = $(".container").width();
    $(".size").html(wrapperSize);
  });

  /**
   * Match Heights
   */
  $(".tabs-list li a, .m3-height, .match-height").matchHeight({
    byRow: true,
  });

  /**
   * Skip Links Focus
   */
  var skipLinks = function () {
    var skip = $(".skip-link");

    $(skip).on("click", function () {
      var skipLink = $(this).attr("href");
      skipLink = skipLink.substring(1, skipLink.length);
      $("#" + skipLink).focus();
    });
  };
  skipLinks();

  /**
   * Search suggestions
   */
  var $siteSearch = $('#site-search');
  $siteSearch.on("keyup", function() {
    var $suggestions = $('#site-suggestions');
    if($(this).val() == "") {
      $suggestions.removeClass('open');
      $searchBox.removeClass('--suggestions-open');
    } else if(!$suggestions.hasClass('open')) {
      var internalSugg = $('#internal-search-sugg').html();
      $suggestions.html(internalSugg);
      $suggestions.addClass('open');
      var $searchBox = $siteSearch.closest('.searchbox');
      $searchBox.addClass('--suggestions-open');
    }
  });

  function attachToSearchClose($el) {
    $el.on('click', function(e) {
      e.preventDefault();
      var $searchBox = $(e.target).closest('.searchbox');
      var $suggestions = $('#site-suggestions');
      $siteSearch.val('');
      
      if($suggestions.hasClass('open')) {
        $suggestions.removeClass('open');
        $searchBox.removeClass('--suggestions-open');
      }
    });
    $el.on('keydown', function(e) {
    if(e.key == 'Enter' || e.key == ' ') {
      e.preventDefault();
      $(this).click();
    }
    });
  }
  
  attachToSearchClose($('.js-close-search'));

  // Ideally we would attach this event when the dropdown is placed on page. 
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        const element = node;
        if (!element.tagName) {
          return;
        }
        if (Array.from(element.classList).includes('js-close-search')) {
          attachToSearchClose($(element));
        }
      });
    });
  });
  
  // Start the observer
  observer.observe(document.body, {
    childList: true,
    subtree: true,
    attributes: false,
    characterData: false,
  });

  $('.js-close-search').on('keydown', function(e) {
    if(e.key == 'Enter' || e.key == ' ') {
      e.preventDefault();
      $(this).click();
    }
  })

  $('.js-close-search').on('click', function(e) {
    e.preventDefault();
    var $searchBox = $(e.target).closest('.searchbox');
    var $suggestions = $('#site-suggestions');
    $siteSearch.val('');
    
    if($suggestions.hasClass('open')) {
      $suggestions.removeClass('open');
      $searchBox.removeClass('--suggestions-open');
    }
  });

  $('.js-close-search').on('keydown', function(e) {
    if(e.key === 'Enter' || e.key === ' '){
      e.preventDefault();
      $(this).trigger('click');
    }
  });

  $('.nav-link[role="tab"]').on('shown.bs.tab', function(event){
    $(event.target).attr('aria-selected', true);
  });

  $('.nav-link[role="tab"]').on('hidden.bs.tab', function(event){
    $(event.target).attr('aria-selected', false);
  });

  $(document).ready(function () {
    // Only handle one particular tablist; if you have multiple tab
    // lists (might even be nested), you have to apply this code for each one
    const $tabLists = $('[role="tablist"]');
    // const $tabs = $tabList.find('> [role="tab"]');
    $tabLists.each(() => {
      const $tabList = $(this);
      // Enable arrow navigation between tabs in the tab list
      let tabFocus = 0;
      const $tabs = $tabList.find('[role="tab"]');
      $tabList.on("keydown", (e) => {
     
        // Move right
        if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
          $tabs[tabFocus].setAttribute("tabindex", -1);
          if (e.key === "ArrowRight") {
            tabFocus++;
            // If we're at the end, go to the start
            if (tabFocus >= $tabs.length) {
              tabFocus = 0;
            }
            // Move left
          } else if (e.key === "ArrowLeft") {
            tabFocus--;
            // If we're at the start, move to the end
            if (tabFocus < 0) {
              tabFocus = $tabs.length - 1;
            }
          }
    
          $tabs[tabFocus].setAttribute("tabindex", 0);
          $tabs[tabFocus].focus();
        }
      });
    })
  
    
  });
  
  

  $(document).on('click', function(e) {
    var $searchBox = $(e.target).closest('.searchbox');
    if($searchBox.length === 0) {
      var $suggestions = $('#site-suggestions');
      if($suggestions.hasClass('open')) {
        $suggestions.removeClass('open');
        $searchBox.removeClass('--suggestions-open');
      }
    }
  });

  /**
   * Fuze search widget customizations
   */

  $("#fuzesswidget").on("click", ".fuzeSSWResults a", function () {
    // update titles here once we know widget is loaded and alive
    $(".inStar1", $("#fuzesswidget"))
      .prop("title", "Not helpful")
      .addClass("inStar")
      .removeClass("actStar")
      .text("No");
    $(".inStar10", $("#fuzesswidget"))
      .prop("title", "Helpful")
      .addClass("inStar")
      .removeClass("actStar")
      .text("Yes");

    // replace "Rate this" with "Was this helpful?"
    $("label", $(".arrow-content")).text("Was this helpful?");

    $(".arrow-container", $("#fuzesswidget")).show();
  });

  $("#fuzesswidget").on("click", ".inStar10", function () {
    $(this).parent().find(".inStar1").addClass("inStar").removeClass("actStar");
    var $detailDiv = $(this).closest(".fuzeSSWidgetResultDetail");

    // hide helpful yes/no options
    var $rateContainer = $detailDiv.find(".arrow-container");
    $rateContainer.hide();

    com.fuze.sswidget.labels.ratingPostedMessage = $(
      "#fuze-submission-items"
    ).data("default-submitted-msg");

    var searchText = com.fuze.sswidget.searchText;

    // put in a fake message to avoid validation msg
    var $commentBox = $detailDiv.find(".rateCommentBox");
    $(".rateCommentTB", $commentBox).val(
      "G1: helpful (Search Query = '" + searchText + "')"
    );

    // before submitting rating, move the response div back out so it doesnt get destroyed
    $("#fuze-submission-responses").insertAfter(".m14").hide();

    var r = /\d+/g;
    var kbid = $detailDiv.prop("id");
    var id = parseInt(kbid.match(r)[0]);
    com.fuze.sswidget.submitRating(id);
  });

  $("#fuzesswidget").on("click", ".inStar1", function () {
    $(this)
      .parent()
      .find(".inStar10")
      .addClass("inStar")
      .removeClass("actStar");

    // reset selection to first option
    $("#fuze-submission-items").val(0);

    // put the select box in this detail
    var $detailDiv = $(this).closest(".fuzeSSWidgetResultDetail");
    var $rateComment = $detailDiv.find(".rateCommentTB");
    $("#fuze-submission-responses").insertAfter($rateComment).show();
  });

  $("#fuze-submission-items").on("change", function () {
    $("#fuze-submission-responses").removeClass("has-validation-error");
  });

  $("#fuze-submit-response").on("click", function (e) {
    e.preventDefault();

    // put in the comment from selected dropdown option
    var selText = $("#fuze-submission-items option:selected").text();
    var selValue = $("#fuze-submission-items option:selected").val();
    var selMsg = $("#fuze-submission-items option:selected").data(
      "submitted-msg"
    );

    // validate
    if (selValue === "0") {
      // add error class to field; show message
      $("#fuze-submission-responses").addClass("has-validation-error");
      return;
    }

    // if field is valid, make sure to remove
    $("#fuze-submission-responses").removeClass("has-validation-error");

    $(this).parent().find(".inStar1").addClass("inStar").removeClass("actStar");
    var $detailDiv = $(this).closest(".fuzeSSWidgetResultDetail");
    var $commentBox = $detailDiv.find(".rateCommentBox");

    // hide helpful yes/no options
    var $rateContainer = $detailDiv.find(".arrow-container");
    $rateContainer.hide();

    var defaultMsg = $("#fuze-submission-items").data("default-submitted-msg");
    var searchText = com.fuze.sswidget.searchText;

    //$(".rateCommentTB", $commentBox).val((selValue > 0 ? selText : "No reason provided") + " (Search Query = '" + searchText + "')");
    $(".rateCommentTB", $commentBox).val(
      selText + " (Search Query = '" + searchText + "')"
    );

    // update submitted message
    com.fuze.sswidget.labels.ratingPostedMessage =
      selMsg === "" ? defaultMsg : selMsg;

    // TODO: if the item had a link, either update the msg span to be clickable to the link
    // or just have a separate button element with configurable button text

    // before submitting rating, move the response div back out so it doesnt get destroyed
    $("#fuze-submission-responses").insertAfter(".m14").hide();

    var r = /\d+/g;
    var kbid = $detailDiv.prop("id");
    var id = parseInt(kbid.match(r)[0]);
    com.fuze.sswidget.submitRating(id);
  });

  /**
   * Accessible Mega menu
   */
  var $megamenu = $(".megamenu");
  $megamenu.accessibleMegaMenu({
    /* prefix for generated unique id attributes, which are required
         to indicate aria-owns, aria-controls and aria-labelledby */
    uuidPrefix: "accessible-megamenu",

    /* css class used to define the megamenu styling */
    menuClass: "nav-menu",

    /* css class for a top-level navigation item in the megamenu */
    topNavItemClass: "nav-item",

    /* css class for a megamenu panel */
    panelClass: "sub-nav",

    /* css class for a group of items within a megamenu panel */
    panelGroupClass: "sub-nav-group",

    /* css class for the hover state */
    hoverClass: "hover",

    /* css class for the focus state */
    focusClass: "focus",

    /* css class for the open state */
    openClass: "open",
  });

  $('.sub-nav').on('focusout', (e) => {
      var $inSubnav = $(e.relatedTarget).closest('.sub-nav');
      if(!$inSubnav.length) {
          $(e.target).closest('.sub-nav').removeClass('open');
      }
  })

  var $activeStartNav = $('.menu__toptab-link.active');
  var startControlledNav = $activeStartNav.attr('aria-controls');
  $("#"+startControlledNav).addClass('active');

  $("nav").removeAttr("role");

  var $subNavClose = $('.sub-nav__mobile-close');

  $subNavClose.each(function() {
     $(this).on('click', function() {
        $(this).closest('.sub-nav').removeClass('open');
     });
  })

  //Close subnav when you click an anchor or button
  var $subnavAnchors = $('.sub-nav a,.sub-nav button');

  $subnavAnchors.each(function() {
    $(this).on('click', function() {
      $(this).closest('.sub-nav').removeClass('open');

      //if we're using the hamburger menu, trigger it
      if(window.innerWidth < 1200)
      {
        $('.menu-toggle').trigger('click');
      }      
    });
  })
      

  var mobileMenu = function () {
    var $mobileSub = $(".menu-toggle");
      var menuId = $mobileSub.attr("aria-controls");
      var $mobileSubMenu = $('#'+menuId);
      var $loginSectionGroup = $(".login-section__group");
  
      $mobileSub.on("click", function (e) {
          if ($mobileSubMenu.hasClass("--open")) {
            $(this).attr("aria-expanded", "false").removeClass("--open");
            $mobileSubMenu.attr("aria-hidden", "true").removeClass("--open");
            $loginSectionGroup.removeClass('--open');
            $('body').removeClass('noscroll');
            $('main').removeClass('noscroll');
          }
          else {
            $(this).attr("aria-expanded", "true").addClass("--open");
            $mobileSubMenu.attr("aria-hidden", "false").addClass("--open");
            $loginSectionGroup.addClass('--open');
            $('body').addClass('noscroll');
            $('main').addClass('noscroll');
          }
        }
      );
    };
    mobileMenu();

  /**
   * Login Modal/Dropdown
   */

  function trapInDialog( dialogToTrap, event ) {

    if ( event.key.toLowerCase() === 'escape' ) {
      $('.btn-close[data-toggle="loginDropdown"]').trigger('click');
    }

    if ( event.key.toLowerCase() !== "tab" ) {

      return;

    }

    // At this point, we know that we're capturing a TAB-related keyboard
    // event ON or IN the Capture container. As such, we need to look at
    // the current state of the DOM (which may be changing dynamically
    // depending on the application logic) to see if we need to override
    // the keyboard navigation. What we're looking for here is any
    // element that is capable of NATURALLY receiving focus (via Tab).
    // --
    // NOTE: jQuery's .add() function results in a collection that is
    // ordered by the DOM ORDER. As such, we can use .first() and .last()
    // on the resultant collection with confidence.
    var tabbable = $()
      // All form elements can receive focus.
      .add( dialogToTrap.find( "button, input, select, textarea" ) )
      // Any element that has an HREF can receive focus.
      .add( dialogToTrap.find( "[href]:visible" ) )
      // Any element that has a non-(-1) tabindex can receive focus.
      .add( dialogToTrap.find( "[tabindex]:not([tabindex='-1'])" ) )
    ;

    var target = $( event.target );

    // Reverse tabbing (Key: Shift+Tab).
    if ( event.shiftKey ) {

      if ( target.is( dialogToTrap ) || target.is( tabbable.first() ) ) {
        console.log(target);
        // Force focus to last element in container.
        event.preventDefault();
        tabbable.last().focus();

      }

    // Forward tabbing (Key: Tab).
    } else {
      if ( target.is( tabbable.last() ) ) {

        // Force focus to first element in container.
        event.preventDefault();
        tabbable.first().focus();

      }

    }

  }

  var $loginDropdown = $('[data-toggle="loginDropdown"]');
  $loginDropdown.on('click keydown', function(e) {
    var isOpen = $(this).attr('id') == 'LogonButton';
    if(isOpen) {
      var browser = getBrowserInfo();
      if(browser.device === 'Mobile') {

        if(!$('.login-dropdown__app').hasClass('active')) $('.login-dropdown__app').addClass('active');

        if(/iOS/.test(browser.os)) {
            var $iosLink = $('.mobile-app-banner__link[data-app-type="ios"]');
            if(!$iosLink.hasClass('show')) {
              $iosLink.addClass('show');
            }
        }
        else if(/Android/.test(browser.os)) {
            var $androidLink = $('.mobile-app-banner__link[data-app-type="android"]')
            if(!$androidLink.hasClass('show')) {
              $androidLink.addClass('show');
            }
        }
      }
    }

    if (e.type == "click" || e.which == 13) {
      var loginDropdownModal = $(this).data("target");
      $(loginDropdownModal).toggleClass("open");

      $('.login-dropdown__backdrop').toggleClass("modal-open");

      if(!isOpen) {
        $('#LogonButton').focus();
      }
      else {
        console.log('open');
        $('#UserName').focus();
      }
    }
  });

  $('.login-dropdown').on('keydown', function(e) {
    trapInDialog($(this), e);
  })
    

  $(document).on('click', function(e) {
    if(e.target.id != 'LogonButton') {
      if($(e.target).closest('.login-dropdown').length === 0) {
        var $backdrop = $('.login-dropdown__backdrop');
        var $modal = $('.login-dropdown');
        $backdrop.removeClass('modal-open');
        $modal.removeClass('open');
      }
    }
  });

  const password = document.querySelector('#Password');
  const closedEyeIcon = document.getElementById('closedEyeIcon');
  const openEyeIcon = document.getElementById('openEyeIcon');

  function togglePasswordVisibility() {
    if(password.type === 'password'){
        password.type = 'text';
        closedEyeIcon.style.display = 'none';
        openEyeIcon.style.display = 'block';
    }
    else {
        password.type = 'password';
        closedEyeIcon.style.display = 'block';
        openEyeIcon.style.display = 'none';
    }
  }

  $(".passwordToggleIcon").on("click", togglePasswordVisibility);
  $(".passwordToggleIcon").on("keydown", function(event) {
    if(event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      togglePasswordVisibility();

      if(password.type === 'password') {
        closedEyeIcon.focus();
      }
      else {
        openEyeIcon.focus();
      }
    }
  });


  /**
   * M1: Carousel
   */
  var m1Carousel = function () {
    var $m1 = $(".m1-carousel");

    /* Carousel Settings */
    var m1Settings = {
      dots: true,
      infinite: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 300,
      slidesToShow: 1,
      adaptiveHeight: true,
      appendDots: $(".dots-clone"),
      prevArrow: $(".slick-prev-custom"),
      nextArrow: $(".slick-next-custom"),
      responsive: [
        {
          breakpoint: 1060,
          settings: {
            arrows: false,
          },
        },
      ],
    };

    /* Initialize Carousel */
    $m1.slick(m1Settings);

    // cant do this because it doesnt blend well with autoplay
    // which pauses on focus
    //$m1.on("afterChange", function () {
    //    $('.slick-current').focus();
    //});
  };
  m1Carousel();

  /**
   * SB1: Mobile Menu
   */
  var sb1MobileMenu = function () {
    var $sb1MenuList = $("ul.main-list");

    $sb1MenuList.each(function (key) {
      var blankOption = "Select an option";
      var $label = $(
        '<label for="sb1-menu-' +
          key +
          '" class="sr-only">' +
          blankOption +
          "</label>"
      );
      var $select = $(
        '<select class="sb1-menu-list" id="sb1-menu-' + key + '" />'
      );

      $select.append($("<option />").text(blankOption));

      //Get the list text and links and create the selectbox menu
      $(this)
        .find("li a")
        .each(function () {
          var listText = $(this).html();
          var listTextLink = $(this).attr("href");

          //Turn the values into a dropdown
          var $option = $("<option />");
          $option.text(listText).attr("value", listTextLink);

          $select.append($option);
        });

      $label.appendTo(".sb1-mobile-menu");
      $select.appendTo(".sb1-mobile-menu");

      //Set the selectbox links
      $select.bind("change", function () {
        var url = $(this).val();
        if (url != "") {
          window.location = url;
        }
        return false;
      });
    });
  };
  sb1MobileMenu();

  /**
   * Accordions - Individual
   */
  var accordion = function () {
    var Accordion = $(".js_accordion"),
      $accordionBtn = $(".js_accordion_trigger--single"),
      $accordionPanel = $(".js_accordion_panel--single");

    $accordionBtn.on("click", function () {
      //console.log($(this).html());
      if ($(this).hasClass("is_expanded")) {
        $(this).removeClass("is_expanded").attr("aria-expanded", "false");
        $(this).next($accordionPanel).slideUp().attr("aria-hidden", "true");
      } else {
        $(this)
          .parents(".js_accordion")
          .find("button.is_expanded")
          .next($accordionPanel)
          .slideUp()
          .attr("aria-hidden", "true");
        $(this)
          .parents(".js_accordion")
          .children()
          .find("button.is_expanded")
          .attr("aria-expanded", "false")
          .removeClass("is_expanded");

        $(this).addClass("is_expanded").attr("aria-expanded", "true");
        $(this).next($accordionPanel).slideDown().attr("aria-hidden", "false");
      }
    });

    $(Accordion).each(function () {
      $(".js_accordion ul li:first")
        .find("button")
        .addClass("is_expanded")
        .attr("aria-expanded", "true");
      $(".js_accordion ul li:first")
        .find(".js_accordion_panel--single")
        .slideDown()
        .attr("aria-hidden", "false");
    });
  };
  accordion();

  /**
   * M28 : Story Steps
   */
  var storySteps = function () {
    var step = $(".js-steps .step"),
      stepNum = $(".js-step-count"),
      currentStep;

    $(step).each(function () {
      currentStep = $(this).index() + 1;
      $(this).find(stepNum).html(currentStep);
    });
  };
  storySteps();

  /**
   * M15 Profiles
   */
  var profiles = function () {
    var $profileTrigger = $(".m15-profile-trigger a");

    $profileTrigger.on("click", function (e) {
      e.preventDefault();
      //get id
      var profileId = $(this).attr("aria-controls");
      console.log(profileId);

      if ($(this).hasClass("profile-open")) {
        $(this)
          .attr("aria-expanded", "false")
          .removeClass("profile-open")
          .find(".m15-profile-text")
          .html("View");
        $("#" + profileId)
          .slideUp()
          .attr("aria-hidden", "true");
        $("html,body").animate({ scrollTop: $(this).offset().top - 200 }, 800);
      } else {
        $(this)
          .attr("aria-expanded", "true")
          .addClass("profile-open")
          .find(".m15-profile-text")
          .html("Close");
        $("#" + profileId)
          .slideDown()
          .attr("aria-hidden", "false");
        $("html,body").animate({ scrollTop: $(this).offset().top - 200 }, 800);
      }
    });
  };
  profiles();

  /**
   * M19 : Site Status Messages
   */
  var siteStatus = function () {
    var $siteError = $(".site-status");

    if ($siteError) {
      var siteStatusMajor = $(".js-close-major");
      var siteStatusMinor = $(".js-close-minor");

      $(siteStatusMajor).on("click", function () {
        //Close message
        $(this).parents(".site-status").slideUp().attr("aria-hidden", "true");
        //write to session
        sessionStorage.setItem("majorStatus", true);
      });

      if (!sessionStorage.getItem("majorStatus")) {
        $(".js-major").slideDown().attr("aria-hidden", "false");
      }

      $(siteStatusMinor).on("click", function () {
        //Close message
        $(this).parents(".site-status").slideUp().attr("aria-hidden", "true");
        //write to session
        sessionStorage.setItem("minorStatus", true);
      });

      if (!sessionStorage.getItem("minorStatus")) {
        $(".js-minor").slideDown().attr("aria-hidden", "false");
      }
    }
  };
  siteStatus();

  var siteStatusFixed = function () {
    var $siteStatusFixed = $(".site-status-fixed");

    // fixed site status is currently used and reserved specifically for the cookie alert
    // therefore it follows a different behavior where users can dismiss the alert until the
    // next time content managers want it to be triggered

    if ($siteStatusFixed) {
      var siteStatusMinor = $(".js-close-minor-fixed", $siteStatusFixed);

      $(siteStatusMinor).on("click", function () {
        $(this)
          .parents(".site-status-fixed")
          .slideUp()
          .attr("aria-hidden", "true");
        localStorage.setItem(
          "minorStatusAlertDismissed",
          new Date().toUTCString()
        );
      });

      var lastRefreshDate = new Date($("#FixedAlertLastRefreshUTC").val());
      console.log("last refresh " + lastRefreshDate);
      var dismissal = localStorage.getItem("minorStatusAlertDismissed");
      if (dismissal === null) {
        $(".js-minor-fixed").slideDown().attr("aria-hidden", "false");
      } else {
        var dismissalDate = new Date(dismissal);
        var utcNow = new Date().getTime();
        var diffMs = Math.abs(utcNow - dismissalDate);
        var diffMin = Math.floor(diffMs / 1000 / 60);
        if (lastRefreshDate > dismissalDate && diffMin > 60) {
          $(".js-minor-fixed").slideDown().attr("aria-hidden", "false");
        }
      }
    }
  };
  siteStatusFixed();

  /**
   * Equal Heights Tables
   */
  function resizeHandler() {
    // Treat each container separately
    $(".comparison-table").each(function (i, container) {
      // Stores the highest row height for all tables in this container, per row
      var aRowHeights = [];
      // Loop through the tables
      $(container)
        .find("table")
        .each(function (indx, table) {
          // Loop through the rows of current table
          $(table)
            .find("tr")
            .css("height", "")
            .each(function (i, tr) {
              // If there is already a row height defined
              if (aRowHeights[i])
                // Replace value with height of current row if current row is higher.
                aRowHeights[i] = Math.max(aRowHeights[i], $(tr).height());
              // Else set it to the height of the current row
              else aRowHeights[i] = $(tr).height();
            });
        });
      // Loop through the tables in this container separately again
      $(container)
        .find("table")
        .each(function (i, table) {
          // Set the height of each row to the stored greatest height.
          $(table)
            .find("tr")
            .each(function (i, tr) {
              $(tr).css("height", aRowHeights[i]);
            });
        });
    });
  }

  $(window).on("load resize", function () {
    resizeHandler();
  });

  //Using the Select option
  var $productSelect = $("select.product-option-select");
  $productSelect.each(function () {
    //On Load
    var $selectedTable;
    var currentSelected = $(this).find(":selected").val();
    $selectedTable = $("#" + currentSelected)
      .clone()
      .appendTo($(this).parents(".comparable-options").next());

    //On Change
    $(this).on("change", function () {
      var $selectedOption = $(this).val();
      $(this).parents(".comparable-options").next().children().remove();
      $selectedTable = $("#" + $selectedOption)
        .clone()
        .appendTo($(this).parents(".comparable-options").next());
      resizeHandler();
    });
  });

  /**
   * Modals
   */

  $(".closeBtn").on("click", function () {
    $.magnificPopup.close();
  });

  var lastFocusedElement;
  $(".compare-accounts").magnificPopup({
    type: "inline",
    midClick: true,
    callbacks: {
      open: function () {
        lastFocusedElement = document.activeElement;
        $(".m23").attr("tabindex", 0).focus();
        resizeHandler();
        $("html, body, main, .mfp-preloader, .skip-link").attr({
          tabindex: -1,
        });
      },
      close: function () {
        $("html, body, main, .mfp-preloader, .skip-link").attr({
          tabindex: "",
        });
        if (lastFocusedElement) {
          $(lastFocusedElement).focus();
        }
      },
    },
  });

  

  $(".popup").magnificPopup({
    type: "inline",
    midClick: true,
    fixedContentPos: false,
    overflowY: "scroll",
    callbacks: {
      open: function () {
        jQuery("body").addClass("noscroll");
        $("html, body, main, .mfp-preloader, .skip-link").attr({
          tabindex: -1,
        });
      },
      close: function () {
        jQuery("body").removeClass("noscroll");
        $("html, body, main, .mfp-preloader, .skip-link").attr({
          tabindex: "",
        });
      },
    },
  });

  /**
   * Filters
   */
  var pageFilters = function () {
    var $filterPanels = $("#filterable");

    var filterTab = $(".btn-filter");

    //On Click
    $(filterTab).on("click", function (e, event) {
      e.preventDefault();

      //Get the panel control ID from the clicked tab
      var filterTabId = $(this).attr("data-filter");

      if (!$(this).hasClass("active-filter")) {
        $("ul.filters-list")
          .find('[aria-selected="true"]')
          .attr({
            "aria-selected": "false",
            tabindex: "-1",
          })
          .removeClass("active-filter");
        $("#filterable")
          .find(".active-filterpanel")
          .removeClass("active-filterpanel")
          .attr("aria-hidden", "true");

        $(this)
          .attr({
            "aria-selected": "true",
            tabindex: "0",
          })
          .addClass("active-filter");

        $("." + filterTabId)
          .addClass("active-filterpanel")
          .attr("aria-hidden", "false");
      }
    });

    var $original, $prev, $next, $target;

    //On Keydown
    $(filterTab).on("keydown", function (e) {
      //Define direction
      $original = $(this);
      $prev = $(this).parents("li").prev().children(".btn-filter");
      $next = $(this).parents("li").next().children(".btn-filter");
      $target;

      //Fid the direction
      switch (e.keyCode) {
        case 37:
          $target = $prev;
          break;
        case 39:
          $target = $next;
          break;
        default:
          $target = false;
          break;
      }

      if ($target.length) {
        //tab switching
        $original
          .attr({
            tabindex: "-1",
            "aria-selected": "false",
          })
          .removeClass("active-filter");
        $target
          .attr({
            tabindex: "0",
            "aria-selected": "true",
          })
          .addClass("active-filter")
          .focus();

        //hide non target panel
        $filterPanels
          .find(".active-filterpanel")
          .removeClass("active-filterpanel")
          .attr("aria-hidden", "true");

        //show the target panel
        $("." + $target.attr("aria-controls"))
          .addClass("active-filterpanel")
          .attr("aria-hidden", "false");
      }
    });

    function clearFilters() {
      // make all child divs active
      $("#filterable")
          .find(".filterpanel")
          .addClass("active-filterpanel")
          .attr("aria-hidden", "false");
        // remove from all buttons
        $(filterTab).removeClass("active-filter");
        // activate the 'all' button
        $("[data-filter=all]").addClass("active-filter");
    }

    //
    $('.sub-nav-item__link').on('click', function (e) {
       //Clear filters if we click a subnav link
       clearFilters();
    });
    
    //Selectbox
    var $filterSelect = $(".filters-select-list");
    $filterSelect.on("change", function () {
      var $el = $(this).val();
      if ($el == "all") {
        clearFilters();
      } else {
        //Show matching div
        var elFilter = "." + $el;
        $(elFilter).addClass("active-filterpanel").attr("aria-hidden", "false");

        //Hide non matching divs
        $("#filterable")
          .find(".filterpanel")
          .not(elFilter)
          .attr("aria-hidden", "true")
          .removeClass("active-filterpanel");

        //Keep desktop buttons in sync
        $(this)
          .parents(".filter-options")
          .children("ul.filters-list")
          .find("li a")
          .not("[data-filter='" + $el + "']")
          .removeClass("active-filter")
          .attr({
            "aria-selected": "false",
            tabindex: "-1",
          });
        $(this)
          .parents(".filter-options")
          .children("ul.filters-list")
          .find("[data-filter='" + $el + "']")
          .addClass("active-filter")
          .attr({
            "aria-selected": "true",
            tabindex: "0",
          });
      }
    });

    var mobileFilters = $(".mobile-filters");
    $(window).on("load resize", function () {
      if ($windowWidth < 767) {
        $(mobileFilters).attr("tabindex", "0");
      } else {
        $(mobileFilters).attr("tabindex", "-1");
      }
    });
  };
  pageFilters();

  $('[data-toggle="tooltip"]').tooltip();

  /* Goal tracking */
  $(function() {
    function triggerGoal(goalId) {
      var goalURL = "/-/media/system/goals/trigger?sc_trk="+goalId;
      $.get(goalURL);
    }

    $("form[data-goal-trigger]").on("submit", function(e) {
      if($(this).valid()) {
        var $goalid = $(this).data("goal-trigger");
        triggerGoal($goalid);
      }
    });

    $(":button[data-goal-trigger]").on("click", function(e) {
      var $goalid = $(this).data("goal-trigger");
      triggerGoal($goalid);
    });

    $("a[data-goal-trigger]").on("click", function(e) {
      var $goalid = $(this).data("goal-trigger");
      triggerGoal($goalid);
    });
  });
});
