$(function () {
    $(document).ready(function () {
        if ($(".rate-promo-rate-value-wrap").length > 0) {
            var ratesText = $('.rate-promo-rate-value-wrap .rate-values').html();
            var delimiterIndex = ratesText.indexOf(': ');

            if (delimiterIndex != -1) {
                var rateCopy = ratesText.substring(delimiterIndex + 2);
                $('.rate-promo-rate-value-wrap .rate-values').html(ratesText.replace(rateCopy, '<span class="rate-promo-rate-value">' + rateCopy + '</span>'));
            }
        }
    });
});