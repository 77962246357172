$(function () {
  $(document).ready(function () {
    $("div.product-module-product")
      .on("click", "ul.product-module-product-list li a", function (e) {
        var $a = $(this);
        var $product = $a.parents("div.product-module-product");

        $a.addClass("active");
        $product.find("ul.product-module-product-list a").removeClass("active");

        var id = document.getElementById($a.data("id"));
        $product.find("div.product-module-product-col-rate").hide();
        $(id).show();
        $("html").animate({ scrollTop: $(id).offset().top - 150 });

        if ($a.attr("href") === "#") {
          e.stopPropagation();
          e.preventDefault();
        }
      })
      .on(
        "change input",
        "div.product-module-product-col-mobile select",
        function () {
          var $select = $(this);
          id = document.getElementById($select.val());
          if (id == null) return false;
          /*$product = $select.parents('div.product-module-product');
            $product.find('div.product-module-product-col-rate').hide();
            $( id ).show();*/
          $('a[data-id="' + $select.val() + '"]').click();
          if ($('a[data-id="' + $select.val() + '"]').attr("href") !== "#") {
            window.open($('a[data-id="' + $select.val() + '"]').attr("href"));
          }
        }
      )
      .on("blur", "div.product-module-product-col-mobile select", function () {
        var $select = $(this);
        id = document.getElementById($select.val());
        if (id == null) return false;
        /*$product = $select.parents('div.product-module-product');
            $product.find('div.product-module-product-col-rate').hide();
            $( id ).attr('tabindex' ,'-1').show().focus();*/
        $('a[data-id="' + $select.val() + '"]').click();
      });

    $("div.rate-module.rate-complex").on(
      "click",
      "a.rate-card-preview-toggle",
      function (e) {
        var $a = $(this);
        var expanded = $a.attr("aria-expanded") === "false" ? true : false;
        $a.attr("aria-expanded", expanded)
          .parents("div.rate-card")
          .toggleClass("rate-card-expanded");
        return false;
      }
    );

    if ($("div.product-module-product-col-mobile select:visible").length) {
      $("div.product-module-product-col-mobile select").trigger("blur");
    }
  });
});
