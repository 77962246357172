$(document).ready(function () {

    var comparisonProducts = [];
    /**
    * 
    */
    $(document).on('change', '.comparison-product-toggle', handleControlClick);
    /**
    * 
    */
    $(document).on('click', ".card-comparison-apply-now-button", ctaClick);
    var clickedCTA = null;

    /**
     * Resides in global space for the overlay when the CTA buttons are created/duplicated dynamically,
     * so they need event bound after their delayed creation.  
     */
    

    function handleControlClick(e) {
        var idElem = $(this).closest("[data-comparison-product-item-id]");
        idElem = idElem.length ? idElem[0] : $(this).closest("[comparison-product-item-id]");
        var comparisonProductItemId = $(idElem).attr('data-comparison-product-item-id');
        comparisonProductItemId = comparisonProductItemId || $(idElem).attr('comparison-product-item-id');

        if (this.checked) {
            if (comparisonProducts.indexOf(comparisonProductItemId) === -1) {
                if (comparisonProducts.length < 3) {
                    comparisonProducts.push(comparisonProductItemId);
                    $(".comparison-product-toggle-error").hide();
                }
                else {
                    $(".comparison-product-toggle-error").hide();
                    $(".comparison-product-toggle-error[comparison-product-item-id='" + comparisonProductItemId + "']").show();
                    this.checked = false;
                }
            }
        }
        else {
            if (this.nodeName === "INPUT" && comparisonProducts.indexOf(comparisonProductItemId) !== -1 ||
                this.nodeName === "DIV") {
                var index = comparisonProducts.indexOf(comparisonProductItemId);
                comparisonProducts.splice(index, 1);
            }
            $(".comparison-product-toggle-error").hide();
        }
        UpdateComparisonProducts();
    }
    /**
    * 
    */
    function UpdateComparisonProducts() {

        var prods = {
            '{58E9A876-C19B-43B2-B387-5F47CFA30788}': 'u2021-ccardDisclaimerCashRewards',
            '{34EAA410-78A9-403B-8D41-0E0909004F28}': 'u2021-ccardDisclaimerCashRewardsPlus',
            '{ECD3072A-EC58-44E2-9FAD-CD892F12A0E9}': 'u2021-ccardDisclaimerMemberRateAdvantage',
            '{2E76B5DD-4051-4763-9D9C-A7E69082B54A}': 'u2021-ccardDisclaimerMemberFirst',
        };

        $('.u2021-compareDisclaimer > p').css('display', '');

        for (var i = 0; i < 4; i++) {

            var $allFields = $(".product-comparison-field[product-comparison-index='" + i + "']");
            $allFields.removeClass(function () { 'u2021-compareCol1 u2021-compareCol2 u2021-compareCol3' });

            if (comparisonProducts.length > i) {
                $allFields.show();
                var productSitecoreItemId = comparisonProducts[i];

                $('.' + prods[productSitecoreItemId]).css('display', 'block');
                $allFields.addClass('u2021-compareCol' + (i + 1));
                var idAttr = "comparison-product-item-id='" + productSitecoreItemId + "'";

                $allFields.each(function (index) {
                    var fieldName = $(this).attr('product-comparison-field-name');
                    var fnAttr = "comparison-product-field-name='" + fieldName + "'";
                    var fieldValueSelector = ".comparison-product-field-data[" + fnAttr + "][" + idAttr + "]";
                    var $field = $(fieldValueSelector);
                    var btnHTML = "<div class='u2021-compareCloseCardBtn' " + fnAttr + " " + idAttr + "></div>";

                    if ($field.length) {
                        $(this).html(btnHTML + $field.html());
                        $(this).attr('data-comparison-product-item-id', productSitecoreItemId);
                    }
                });
            }
            else {
                $allFields.hide();
            }
        }
        $('input.comparison-product-toggle').each(function (index) {
            this.checked = comparisonProducts.indexOf($(this).attr("comparison-product-item-id")) !== -1;
        });

        $('#u2021-ccardCompareDock').toggleClass('u2021-minimized', comparisonProducts.length <= 0);
        $('#u2021-ccardCompareDock #u2021-compareBtn').toggleClass('u2021-greyedOut', comparisonProducts.length <= 1);
        if (comparisonProducts.length === 2) {
            $('#u2021-ccardCompareGrid').addClass('u2021-grid2Col');
            $('#u2021-ccardCompareGrid').removeClass('u2021-grid3Col');
        } else if (comparisonProducts.length) {
            $('#u2021-ccardCompareGrid').addClass('u2021-grid3Col');
            $('#u2021-ccardCompareOverlay').removeClass('u2021-grid2Col');
        }

        $('.u2021-ccardCompareDock .u2021-compareCloseCardBtn').on('click', handleControlClick);
        $('.u2021-ccardCompare .card-comparison-apply-now-button').on('click', ctaClick);
        $('.u2021-ccardCompare .card-comparison-learn-more-button').on('click', ctaClick);


    }
    /**
    * For main site nav, since it stays visible in mobile, close overlays if it gets clicked.
    */
    $('.header-navigation').on('click', function () {
        $('.u2021-overlay').addClass('u2021-minimized');
        $('body').removeClass(("modal-open")); // Re-enable scrolling in body.
    })
    /**
    * 
    */
    $(".u2021-closeBtn").each(function (index) {
        var self = this;
        $(this).on("click", function (e) {
            $(self).closest('.u2021-overlay').addClass("u2021-minimized");
            $('body').removeClass(("modal-open")); // Re-enable scrolling in body.
        });
    });
    /**
    * Compare slideouts.
    */
    $("#u2021-ccardCompareDock #u2021-compareBtn").on('click', function () {
        $('#u2021-ccardCompareOverlay').removeClass('u2021-minimized', false);
        $("body").addClass("modal-open"); // Bootstrap trick, kills scrolling in the body.
    });
});

function ctaClick(e) {
    clickedCTA = e.currentTarget;
    applyHREF = $(e.currentTarget).attr("href");
    //if (applyHREF.indexOf('&type=') !== -1) {
        window.location.href = applyHREF;
    /*} else {
        $('#u2021-wizardCTAModal').modal('show');
    }*/
}