$(function () {
    $(document).ready(function () {

        $('#quote-tool-form fieldset').first().fadeIn('slow');
        var crntStep = 0;
        $('.quote-tool-form input[type="text"], .quote-tool-form input[type="number"], .quote-tool-form input[type="email"]').on('focus', function () {
            removeError($(this));
        });
        if (typeof myHomeLoansResultPage !== 'undefined') {
            LoanPurposeUpdateUI();
        }

        $('.quote-tool-startover-btn').on('click', function () {
            location.reload();
        });

        // next step
        $('#quote-tool-form .btn-next').on('click', function () {
            var parent_fieldset = $(this).parents('fieldset');
            var validationDisabled = false;
            if ($(this).attr('validation-disabled') == 'true') {
                validationDisabled = true;
            }
            else {
                $('#formStatus' + $(this).attr('flex-form-id')).val($('#formStatus' + $(this).attr('flex-form-id')).val() + '-Step:' + validationStep);
            }
            var validationStep = '0';
            validationStep = $(this).attr('validation-step');
            if (!($(this).hasClass('btn-next'))) {
                return;
            }
            else if ($(this).hasClass('quote-tool-btn-start')) {
                var startWizard = true;
                console.log('btnStart click');
                console.log($(this).attr('flex-form-id'));
                console.log($(this));
                
                if (!validationDisabled) {
                    startWizard = validateFlexForm($(this).attr('flex-form-id'), 'Purchase', 'flex-form-validation', validationStep);
                    
                }
                /*parent_fieldset.find('input').each(function () {
                    if ($(this).hasClass('input-error')) {
                        startWizard = false;
                    }
                });*/
                if (startWizard) {
                    $('.progress-bar-group').show();
                    goToNextStep(parent_fieldset);
                }
            }
            else {
                
                   
                var next_step = true;
                if (!validationDisabled) {
                    next_step = validateFlexForm($(this).attr('flex-form-id'), 'Purchase', 'flex-form-validation', validationStep);
                }
                /*parent_fieldset.find('input[required]').each(function () {
                    if ($(this).val() == "") {
                        setError($(this), golden1Dictionary.CompleteAllQuestions);
                        next_step = false;
                    }
                });
                parent_fieldset.find('input').each(function () {
                    if ($(this).hasClass('input-error')) {
                        startWizard = false;
                    }
                });*/
                if (next_step) {
                    if ($(this).hasClass('btn-submit')) {
                        $('.wizard-submit-loading').show();
                        submitQuoteToolFormWithRecaptcha();
                    }
                    else {
                        goToNextStep(parent_fieldset);
                    }
                    
                    setProgressBar(crntStep);
                    crntStep = crntStep + 1;
                }
            }
        });

        // previous step
        $('#quote-tool-form .btn-previous').on('click', function () {
            $(this).parents('fieldset').fadeOut(400, function () {
                $(this).prev().fadeIn();
            });
        });

        // previous step
        $('#quote-tool-form .btn-back').on('click', function () {
            console.log("count step value on click " + crntStep);
            crntStep = crntStep-2;
            setProgressBar(crntStep);
            crntStep = crntStep + 1;
            $(this).parents('fieldset').fadeOut(400, function () {
                $(this).prev().fadeIn();
            });
            
        });

        $('#PhoneNumber').blur(function () {
            $(this).val($(this).val().replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '$1-$2-$3'))
        });

        
        //step-1
        $('.radio-group .radio-choice').on('click', function () {
            var radioGroup = $(this).parent();
            radioGroup.find('.radio-choice').removeClass('selected');
            $(this).addClass('selected');

            var value = $(this).attr('value');
            radioGroup.find('#occupancy').val(value);

            var parent_fieldset = $(this).parents('fieldset');
            goToNextStep(parent_fieldset);
            setProgressBar(crntStep);
            crntStep = crntStep + 1;
        });


        //step-2
        $('#downPayment').on('input change blur', function () {
            var amount = parseInt($('#purchasePrice').val().replace(/[^\d\.]/g, ''));
            var downPaymentAmount = parseInt($(this).val().replace(/[^\d\.]/g, ''));
            if (downPaymentAmount && amount) {
                if (downPaymentAmount > amount) {
                    //setError($(this), golden1Dictionary.DownPaymentAmountInvalid);
                    return;
                }
                else {
                    var downPaymentPercent = parseFloat((downPaymentAmount / amount) * 100).toFixed(2);
                    $('#downPaymentPercent').val(downPaymentPercent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    //removeError($(this));
                    //removeError($('#downPaymentPercent'));
                }
            }
            else if (!amount) {
                //setError($('#purchasePrice'), golden1Dictionary.PurchaseAmountRequired);
                return;
            }
        });
        
        $('#downPaymentPercent').on('input change blur', function () {
            var amount = parseInt($('#purchasePrice').val().replace(/[^\d\.]/g, ''));
            var downPaymentPercent = parseFloat($(this).val().replace(/[^\d\.]/g, ''));
            if (downPaymentPercent && amount) {
                if (downPaymentPercent > 100) {
                    setError($(this), golden1Dictionary.DownPaymentPercentInvalid);
                    return;
                }
                else {
                    var downPaymentAmount = parseInt((downPaymentPercent / 100) * amount);
                    $('#downPayment').val(downPaymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    removeError($(this));
                    removeError($('#downPayment'));
                }
            }
            else if (!amount) {
                setError($('#purchasePrice'), golden1Dictionary.PurchaseAmountRequired);
            }
        });

        $('#purchasePrice').on('input change blur', function () {
            var amount = parseInt($(this).val().replace(/[^\d\.]/g, ''));
            //var amountStr = ($('#purchasePrice').val()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            //var amount = parseFloat(amountStr.replace(/[^\d\.]/g, ''));

            var downPaymentAmount = parseInt($('#downPayment').val().replace(/[^\d\.]/g, ''));
            var downPaymentPercent = parseFloat($('#downPaymentPercent').val().replace(/[^\d\.]/g, ''));
            if (amount) {
                if (downPaymentPercent && downPaymentPercent <= 100) {
                    downPaymentAmount = parseInt((downPaymentPercent / 100) * amount);
                    $('#downPayment').val(downPaymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    removeError($(this));
                    removeError($('#downPayment'));
                }
                else if (downPaymentAmount && amount > downPaymentAmount) {
                    downPaymentPercent = parseFloat((downPaymentAmount / amount) * 100).toFixed(2);
                    $('#downPaymentPercent').val(downPaymentPercent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    removeError($(this));
                    removeError($('#downPaymentPercent'));
                }
                else if (downPaymentPercent || downPaymentAmount) {
                    setError($(this), golden1Dictionary.PurchaseAmountInvalid);
                    return;
                }
            }
        });

        //step-3        
        $('#quote-tool-form-non-first .btn-submit').on('click', function () {
            var submitForm = validateFlexForm($(this).attr('flex-form-id'), $('#currentValidationGroup').val(), 'flex-form-validation', $('#currentValidationStep').val());
            var form = $('#quote-tool-form-non-first');
            if (submitForm) {
                $('.wizard-submit-loading').show();
                //console.log(form);
                submitFormWithRecaptcha(form);
                
                return true;
            }
        });

        //functions
        function togglePurchaseRefinance() {
            var form = $('#quote-tool-form-non-first');
            var loanPurpose = form.find('#loanPurpose').val();
            var loanType = loanPurpose.trim().toLowerCase();
            var purchaseGroup = form.find('.purchase-group');
            var refinanceGroup = form.find('.refinance-group');
            console.log('loanType' + loanType);
            if (loanType == "purchase") {
                console.log('_purchase');
                purchaseGroup.show();
                toggleDataActiveForInputs(purchaseGroup, 'true');
                refinanceGroup.hide();
                toggleDataActiveForInputs(refinanceGroup, 'false');
            }
            else if (loanType == "refinance") {
                console.log('_refinance');
                purchaseGroup.hide();
                toggleDataActiveForInputs(purchaseGroup, 'false');
                refinanceGroup.show();
                toggleDataActiveForInputs(refinanceGroup, 'true');
            }
            else {
                console.log('_else');
                purchaseGroup.hide();
                toggleDataActiveForInputs(purchaseGroup, 'false');
                refinanceGroup.hide();
                toggleDataActiveForInputs(refinanceGroup, 'false');
            }
        }
        function toggleDataActiveForInputs(inputGroup, value) {
            inputGroup.find('input').each(function () {
                $(this).attr('data-active', value);
            });
        }
        function setProgressBar(curStep) {
            //console.log('curStep = ' + curStep);
            $('#formStatus').val($('#formStatus').val() + 'Step:' + curStep);
            $('#currentValidationStep').val(curStep);
            var steps = $('.quote-tool-form fieldset .quote-tool-step').length;
            var percent = parseFloat(100 / steps) * curStep;
            percent = percent.toFixed();
            
            if (curStep == 0) {
                percent = .5;
            }
            else if (curStep == 1) {
                percent = 33.5;
            }
            else if (curStep == 2) {
                percent = 66.5;
            }
            else if (curStep == 3) {
                percent = 100;
            }
            else {
                percent = 100;
            }
            $("#progress-bar")
                .css("width", percent + "%")
        }

        

        function setError(currentField, errorMessage) {
            var parent_fieldset = currentField.parents('fieldset');
            if (parent_fieldset.length === 0) {
                parent_fieldset = currentField.parents('form');
            }
            parent_fieldset.find('.error-message').html(errorMessage);
            currentField.addClass('input-error');
        }

        function removeError(currentField) {
            var parent_fieldset = currentField.parents('fieldset');
            if (parent_fieldset.length === 0) {
                parent_fieldset = currentField.parents('form');
            }
            parent_fieldset.find('.error-message').html('');
            currentField.removeClass('input-error');
        }

        function goToNextStep(parentFieldset) {
            parentFieldset.fadeOut(400, function () {
                $(this).next().fadeIn();
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('.assessment-container').offset().top - 100
                }, 500);
            });
        }

        function submitQuoteToolFormWithRecaptcha() {
            var form = $('#quote-tool-form');
            submitFormWithRecaptcha(form);
        }
        function submitFormWithRecaptcha(form) {
            if (typeof grecaptcha !== "undefined" && grecaptcha && golden1Dictionary.QuoteToolRecaptchaSiteKey && golden1Dictionary.QuoteToolRecaptchaAction) {
                grecaptcha.ready(function () {
                    grecaptcha.execute(golden1Dictionary.QuoteToolRecaptchaSiteKey, { action: golden1Dictionary.QuoteToolRecaptchaAction }).then(function (token) {
                        form.find("input[name='recaptchaResponse']").val(token); 
                        
                        form.submit();
                    });
                });
            }
            else {
                form.submit();
            }
        }
        $('.home-loan-apply-now-attribution-link').on('click', function () {
            var applyNowUrlFromHidden = $('#ApplyNowUrl').val();
            if (applyNowUrlFromHidden != '') {
                window.location = $('#ApplyNowUrl').val();
            }
        });
        var inputUpdating = false;
        $('#DownPaymentPercentTextBox').on('change input', function () {
            if (!inputUpdating) {
                //console.log('DownPaymentPercentTextBox CHANGE');
                inputUpdating = true;
                var decFromTB = $('#DownPaymentPercentTextBox').val().replace(/[^\d\.]/g, '') / 100.00;
                //console.log('decFromTB' + decFromTB);
                var downPaymentInDollars = $('#PurchasePriceTextBox').val().replace(/[^\d\.]/g, '') * decFromTB;
                if (downPaymentInDollars != "NaN")
                    $('#DownPaymentTextBox').val(downPaymentInDollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    
                var isValid = false;
                
                inputUpdating = false;

                //end dupe code
            }
        });

        $('#DownPaymentTextBox').on('change input', function () {
            if (!inputUpdating) {
                inputUpdating = true;
                var percentdec = $('#DownPaymentTextBox').val().replace(/[^\d\.]/g, '') / $('#PurchasePriceTextBox').val().replace(/[^\d\.]/g, '');
                console.log('percentdec' + percentdec);
                var downPaymentInPercent = percentdec * 100;
                if ($('#PurchasePriceTextBox').val().replace(/[^\d\.]/g, '') == '' || $('#PurchasePriceTextBox').val().replace(/[^\d\.]/g, '') <= 0 || downPaymentInPercent == 0)
                    $('#DownPaymentPercentTextBox').val('');
                else {
                    if (downPaymentInDollars != "NaN")
                        $('#DownPaymentPercentTextBox').val(downPaymentInPercent.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                }
                console.log('DownPaymentTextBox CHANGE ' + $('#DownPaymentTextBox').val());
                var isValid = false;
                if ($('#SubmittedStatus').val() != '') {
                    
                }
                inputUpdating = false;
            }
        });
        $('#downPaymentTextBox').on('change input', function () {
            if ($(this).val() == 'NaN') {
                $(this).val('');
            }
                
        });

        $('#loanPurpose').on('change input', function () {
            console.log('$(#loanPurpose).on(change input, function ()');
            if (!inputUpdating) {
                LoanPurposeUpdateUI();
                var isValid = false;
                if ($('#SubmittedStatus').val() != '') {
                }
                inputUpdating = false;
            }
        });

        function LoanPurposeUpdateUI() {
            var loanPurposeVal = $('#loanPurpose').val();
            //console.log('LoanPurposeUpdateUI() ' + loanPurposeVal);
            //console.log($('#loanPurpose'));
            if (loanPurposeVal == "106" || loanPurposeVal == "") {
                $('.my-home-loan-rates-refinance-view').hide();
                $('.my-home-loan-rates-purchase-view').show();
                $('#currentValidationGroup').val("Purchase");
            }
            else {
                $('.my-home-loan-rates-refinance-view').show();
                $('.my-home-loan-rates-purchase-view').hide();
                $('#currentValidationGroup').val("Refinance");
            }
        }
        
        $('#PurchasePriceTextBox').on('change', function () {            
            try {
                var purchasePrice = parseInt($('#PurchasePriceTextBox').val());
                //console.log('purchase price: ' + purchasePrice);
                var purchasePriceWithCents = purchasePrice;
                //console.log('purchase price with cents: ' + purchasePriceWithCents);
                if (purchasePrice != purchasePriceWithCents) {
                    //console.log('(purchasePrice != purchasePriceWithCents)');
                    $('#PurchasePriceTextBox').val(purchasePriceWithCents);
                    //console.log($('#PurchasePriceTextBox').val());
                }
                else {
                    //console.log('(purchasePrice === purchasePriceWithCents)');
                }
            }
            catch (ex) {
                console.log(ex);
            }
        });

        $('.my-home-loan-rates-textbox-with-dollar').on('change', function () {

            try {
                var purchasePrice = parseInt($(this).val().replace(/[^\d\.]/g, ''));
                //console.log('this purchase price: ' + purchasePrice);
                var purchasePriceWithCents = purchasePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if (purchasePriceWithCents != "NaN") {
                    console.log('this purchase price with cents: ' + purchasePriceWithCents);
                    //if (purchasePrice != purchasePriceWithCents) {
                    //console.log('(purchasePrice != purchasePriceWithCents)');
                    $(this).val(purchasePriceWithCents);
                    //console.log($(this).val());
                    /*}
                    else {
                        console.log('this (purchasePrice === purchasePriceWithCents)');
                    }*/
                }
            }
            catch (ex) {
                console.log(ex);
            }
        });

        $('.my-home-loan-rates-textbox-with-percent').on('change', function () {

            try {
                var purchasePrice = parseFloat($(this).val().replace(/[^\d\.]/g, ''));
                //console.log('this purchase price: ' + purchasePrice);
                var purchasePriceWithCents = purchasePrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if (purchasePriceWithCents != "NaN") {
                    console.log('this purchase price with cents: ' + purchasePriceWithCents);
                    //if (purchasePrice != purchasePriceWithCents) {
                    //console.log('(purchasePrice != purchasePriceWithCents)');
                    $(this).val(purchasePriceWithCents);
                    //console.log($(this).val());
                    /*}
                    else {
                        console.log('this (purchasePrice === purchasePriceWithCents)');
                    }*/
                }
            }
            catch (ex) {
                console.log(ex);
            }
        });

        $('.text-box-with-commas').on('change', function () {

            try {
                var downPayment = parseInt($(this).val().replace(/[^\d\.]/g, ''));
                //console.log('this purchase price: ' + purchasePrice);
                var downPaymentWithCents = downPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if (downPaymentWithCents != "NaN") {
                    console.log('this down payment with cents: ' + downPaymentWithCents);
                    //if (purchasePrice != purchasePriceWithCents) {
                    //console.log('(purchasePrice != purchasePriceWithCents)');
                    $(this).val(downPaymentWithCents);
                    //console.log($(this).val());
                    /*}
                    else {
                        console.log('this (purchasePrice === purchasePriceWithCents)');
                    }*/
                }
            }
            catch (ex) {
                console.log(ex);
            }
        });

        $('.percent-textbox').on('change', function () {

            try {
                var purchasePrice = parseFloat($(this).val().replace(/[^\d\.]/g, ''));
                //console.log('this purchase price: ' + purchasePrice);
                var purchasePriceWithCents = purchasePrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //console.log('this purchase price with cents: ' + purchasePriceWithCents);
                //if (purchasePrice != purchasePriceWithCents) {
                //console.log('(purchasePrice != purchasePriceWithCents)');
                if (purchasePriceWithCents != "NaN") {
                    $(this).val(purchasePriceWithCents);
                    console.log('purchasePriceWithCents: ' + purchasePriceWithCents);
                }
                //console.log($(this).val());
                /*}
                else {
                    console.log('this (purchasePrice === purchasePriceWithCents)');
                }*/
            }
            catch (ex) {
                console.log(ex);
            }



        });

        $('.percent-textbox-commas').on('change', function () {

            try {
                var purchasePrice = parseFloat($(this).val().replace(/[^\d\.]/g, ''));
                //console.log('this purchase price: ' + purchasePrice);
                var purchasePriceWithCents = purchasePrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //console.log('this purchase price with cents: ' + purchasePriceWithCents);
                //if (purchasePrice != purchasePriceWithCents) {
                //console.log('(purchasePrice != purchasePriceWithCents)');
                if (purchasePriceWithCents != "NaN") {
                    $(this).val(purchasePriceWithCents);
                }
                //console.log($(this).val());
                /*}
                else {
                    console.log('this (purchasePrice === purchasePriceWithCents)');
                }*/
            }
            catch (ex) {
                console.log(ex);
            }



        });
        

        $('.my-home-loan-rates-textbox').on('change input', function () {
            $('.my-home-loans-update-button-submitted').removeClass('my-home-loans-update-button-submitted');
            if (!inputUpdating && $('#SubmittedStatus').val() != '') {


            }
        });

        $('.quote-tool-email-submit').on('click', function () {
            var emailTextboxId = $(this).attr('email-textbox-id');
            var emailTextBox = $('#' + emailTextboxId);
            var flexFormId = $(emailTextBox).attr('flex-form-id');

            var validatorCssClass = 'flex-form-validation';
            var validationGroup = $(this).attr('validation-group');

            var validationStep = $(this).attr('validation-step');

            var currentValidationStep = $('#currentValidationStep').val();
            var validationGroup = $('#currentValidationGroup').val();

            var recaptchaSiteKey = $(this).attr('recaptcha-site-key');
            var recaptchaAction = $(this).attr('recaptcha-action');
            console.log('trigger validation on input - flex-form-id=' + flexFormId + ' ,validator-css-class=' + validatorCssClass + ' ,validation-group=' + validationGroup);
            var formIsValid = false;
            {
                formIsValid = validateFlexForm(flexFormId, validationGroup, validatorCssClass, currentValidationStep);
            }
            if (formIsValid && $('#' + emailTextboxId).val() != '') {

                var url = '/api/AtlasFormsServices/QuoteToolEmailEntryAync';
                //var formData = new FormData();//form);
                var fd = { emailAddress: $(emailTextBox).val() };

                if (typeof grecaptcha !== "undefined" /*&& grecaptcha && formIsValid && recaptchaSiteKey && recaptchaAction*/) {
                    console.log('grecaptcha sitekey and action');
                    console.log(recaptchaSiteKey);
                    console.log(recaptchaAction);
                    //$('#' + dynamicFormSubmitLoadingId).show();
                    grecaptcha.ready(function () {
                        console.log('grecaptcha ready');
                        console.log(grecaptcha);
                        formSubmitted = true;
                        grecaptcha.execute(recaptchaSiteKey, ({ action: recaptchaAction })).then(function (token) {

                            recaptchaToken = token;
                            console.log('token');
                            console.log(recaptchaToken);
                            //formData.append('RecaptchaResponse', recaptchaToken);
                            
                            var fdWithRecaptcha = {
                                emailAddress: $(emailTextBox).val(),
                                RecaptchaResponse: recaptchaToken
                            };
                            $.ajax({
                                data: JSON.stringify(fdWithRecaptcha),
                                type: 'post',
                                url: url,
                                //enctype: 'multipart/form-data',
                                processData: false,
                                //contentType: false,
                                dataType: 'json',
                                success: function (data) {
                                    console.log('email submit success');
                                    console.log(data);
                                },
                                error: function (data) {
                                    console.log('email submit error');
                                    console.log(data);
                                }
                            });
                        });

                    });
                }
                else {
                    $.ajax({
                        data: JSON.stringify(fd),
                        type: 'post',
                        url: url,
                        //enctype: 'multipart/form-data',
                        processData: false,
                        //contentType: false,
                        dataType: 'json',
                        success: function (data) {
                            console.log('email submit success');
                            console.log(data);
                        },
                        error: function (data) {
                            console.log('email submit error');
                            console.log(data);
                        }
                    });
                }
                
                
            }
        });
    });

});


function submitFlexForm(flexFormId) {
    console.log('submitFlexForm(flexFormId)');
    return submitFlexForm(flexFormId, '');
}
function submitFlexForm(flexFormId, validationGroup) {
    var validatorCssClass = 'flex-form-validation';
    console.log('submitFlexForm(flexFormId, validationGroup)');
    return validateFlexForm(flexFormId, validationGroup, validatorCssClass);
}
function submitFlexForm(flexFormId, validationGroup, validatorCssClass) {
    var validatorCssClass = 'flex-form-validation';
    console.log('submitFlexForm(flexFormId, validationGroup, validatorCssClass)');
    return validateFlexForm(flexFormId, validationGroup, validatorCssClass, $('currentValidationStep').val());
}
function validateFlexForm(flexFormId, validationGroup, validatorCssClass, currentValidationStep) {
    //flex-form-validation
    //console.log('validateFlexForm(' + flexFormId + ',' + validationGroup + ',' + validatorCssClass, currentValidationStep);
    var isValid = true;
    $('#formStatus' + flexFormId).val($('#formStatus' + flexFormId).val() + 'validating' + validationGroup);
    var validatorSelectorString = '.' + validatorCssClass + "[flex-form-id='" + flexFormId + "']";
    var validators = $(validatorSelectorString);
    //console.log(validators);
    validators.each(function (index) {
        var validator = $(this);
        var validationFor = $(this).attr('validation-for');
        var validatorErrorCssClass = $(this).attr('validation-error-css-class');
        //console.log('Validator - validation-for=' + validationFor);
        var requiredFieldValidator = false;
        if (validationFor != '') {
            var inputField = $('#' + validationFor);
            var inputFieldValue = inputField.val();
            //console.log('input-field');
            //console.log(inputField);
            var inputErrorCssClass = inputField.attr('validation-error-css-class');
            var inputFieldIsValid = true;
            inputField.removeClass(inputErrorCssClass);
            validator.removeClass(validatorErrorCssClass);
        }

    });
    validators.each(function (index) {
        var validator = $(this);
        var validationFor = $(this).attr('validation-for');
        var validatorErrorCssClass = $(this).attr('validation-error-css-class');
        //console.log('Validator - validation-for=' + validationFor);
        var requiredFieldValidator = false;
        if (validationFor != '') {
            var inputField = $('#' + validationFor + "[flex-form-id='" + flexFormId + "']");
            var inputFieldValue = inputField.val();
            //var validator = $('#' + validationFor);
            var validatorValidationGroup = $(validator).attr('validation-group');
            var validatorValidationStep = $(validator).attr('validation-step');
            //console.log('input-field');
            //console.log(inputField);
            var inputErrorCssClass = inputField.attr('validation-error-css-class');
            var inputFieldIsValid = true;
            //console.log("validationGroup: " + validationGroup + ", validatorValidationGroup: " + validatorValidationGroup);
            //console.log(currentValidationStep );
            if (!currentValidationStep || validatorValidationStep == '' || currentValidationStep == '' || currentValidationStep == validatorValidationStep) {
                if (validatorValidationGroup == '' || validationGroup == validatorValidationGroup) {
                    if ($(this).attr('validation-required-field') == 'True') {
                        //console.log('required field validator');
                        if (inputFieldValue == '' || typeof inputFieldValue === 'undefined') {
                            inputFieldIsValid = false;
                        }
                        else {
                        }

                    }
                    if ($(this).attr('validation-regular-expression') != '' && inputFieldValue != '') {
                        var reggieText = $(this).attr('validation-regular-expression');
                        reggieText = reggieText.replace('&#39;', '\'');
                        var reggie = new RegExp(reggieText);
                        //console.log(reggie);
                        inputFieldIsValid = reggie.test(inputFieldValue);
                    }
                    //console.log('inputFieldIsValid=' + inputFieldIsValid);
                    if (!inputFieldIsValid) {
                        isValid = false;
                        inputField.addClass(inputErrorCssClass);
                        validator.addClass(validatorErrorCssClass);
                    }
                }
            }
        }
        
    });
    $('#formStatus' + flexFormId).val($('#formStatus' + flexFormId).val() + 'validated');
    return isValid;
}

$(function () {
    $(document).ready(function () {
        $('.flex-form-form').on('submit', function (form) {
            var flexFormId = $(this).attr('flex-form-id');
            var validatorCssClass = 'flex-form-validation';
            var validationGroup = $(this).attr('validation-group');
            //console.log('Flex Form Submit Post - flex-form-id=' + flexFormId + ' ,validator-css-class=' + validatorCssClass + ' ,validation-group=' + validationGroup);
            var currentValidationStep = $('#currentValidationStep').val();
            var formIsValid = validateFlexForm(flexFormId, validationGroup, validatorCssClass, currentValidationStep);
            var flexFormSubmitLoadingId = 'flex-form-submit-loading-' + flexFormId;
            var recaptchaSiteKey = $(this).attr('recaptcha-site-key');
            var recaptchaAction = $(this).attr('recaptcha-action');
            var formAction = $(this).attr('action');
            var formMethod = $(this).attr('method');
            console.log(recaptchaAction + " " + recaptchaSiteKey);
            var formData = $(this).serializeArray();
            if (formIsValid ) {
                $('#' + flexFormSubmitLoadingId).show();
                
                if (typeof grecaptcha !== "undefined" && grecaptcha && recaptchaSiteKey && recaptchaAction/* && formIsValid*/) {
                    console.log('grecaptcha sitekey and action');
                    grecaptcha.ready(function () {
                        console.log('grecaptcha ready');
                        console.log(grecaptcha);
                        formSubmitted = true;
                        grecaptcha.execute(recaptchaSiteKey, ({ action: recaptchaAction })).then(function (token) {

                            recaptchaToken = token;
                            console.log('token');
                            console.log(recaptchaToken);
                            formData.push({ name: 'RecaptchaResponse', value: recaptchaToken });
                            $.ajax({
                                data: formData,
                                type: formMethod,
                                url: formAction,
                                success: function (data) {
                                    $('.flex-form-submit-success').show();
                                    $('.flex-form-submit-success-hide').hide();
                                    $('.flex-form-submit-error').hide();
                                    $('#' + flexFormSubmitLoadingId).hide();
                                    console.log('flex form submit success');
                                    console.log(data);
                                },
                                error: function (data) {
                                    $('.flex-form-submit-error').show();
                                    $('.flex-form-submit-success').hide();
                                    $('#' + flexFormSubmitLoadingId).hide();
                                    console.log('flex form submit error');
                                    console.log(data);
                                }
                            });
                        });

                    });

                }
                else if( formIsValid) {
                    formData.push({ name: 'RecaptchaResponse', value: 'Recpatcha not Working' });
                    console.log('recaptcha not enabled.');
                    $.ajax({
                        data: formData,
                        type: formMethod,
                        url: formAction,
                        success: function (data) {
                            $('.flex-form-submit-success').show();
                            $('.flex-form-submit-success-hide').hide();
                            $('.flex-form-submit-error').hide();
                            $('#' + flexFormSubmitLoadingId).hide();
                            console.log('flex form submit success');
                            console.log(data);
                        },
                        error: function (data) {
                            $('.flex-form-submit-error').show();
                            $('.flex-form-submit-success').hide();
                            $('#' + flexFormSubmitLoadingId).hide();
                            console.log('flex form submit error');
                            console.log(data);
                        }
                    });
                }
            }
            
            return false;
        })
        $('.trigger-validation').on('click', function () { 
            var flexFormId = $(this).attr('flex-form-id');
            var validatorCssClass = $(this).attr('validator-css-class');
            if (typeof validatorCssClass === 'undefined' || validatorCssClass == '')
                validatorCssClass = 'flex-form-validation';
            console.log('Flex Form Submit Click - flex-form-id=' + flexFormId + ' ,validator-css-class=' + validatorCssClass + ' ,validation-group=' + validationGroup);
            var validationGroup = $(this).attr('validation-group');
            var currentValidationStep = $('#currentValidationStep').val();
            var formIsValid = validateFlexForm(flexFormId, validationGroup, validatorCssClass, currentValidationStep);
            
        })

        $('.flex-form-input').on('change input', function () {
            console.log('on change input ' + $(this).attr('id') + '  ' + $(this).val() );
            var flexFormId = $(this).attr('flex-form-id');
            var formStatus = $('#formStatus' + flexFormId).val();
            if (formStatus != '' && formStatus !== 'undefined') {
                var validatorCssClass = 'flex-form-validation';//+ formStatus;
                var validationGroup = $('#currentValidationGroup').val();
                console.log('trigger validation on input - flex-form-id=' + flexFormId + ' ,validator-css-class=' + validatorCssClass + ' ,validation-group=' + validationGroup);
                var currentValidationStep = $('#currentValidationStep').val();
                
                var formIsValid = validateFlexForm(flexFormId, validationGroup, validatorCssClass, currentValidationStep);
            }
            

        })
        //todo: remove this copy and pase
        $('.flex-form-validated-input').on('change input', function () {
            console.log('on change input ' + $(this).attr('id') + '  ' + $(this).val());
            var flexFormId = $(this).attr('flex-form-id');
            var formStatus = $('#formStatus' + flexFormId).val();
            if (formStatus != '' && typeof formStatus !== 'undefined') {
                var validatorCssClass = 'flex-form-validation';//+ formStatus;
                var validationGroup = $(this).attr('validation-group');

                var validationStep = $(this).attr('validation-step');

                var currentValidationStep = $('#currentValidationStep').val();
                var validationGroup = $('#currentValidationGroup').val();
                console.log('trigger validation on input - flex-form-id=' + flexFormId + ' ,validator-css-class=' + validatorCssClass + ' ,validation-group=' + validationGroup);
                var formIsValid = false;
                if (currentValidationStep != '') {
                    if (formStatus.includes('Step:' + currentValidationStep))
                        formIsValid = validateFlexForm(flexFormId, validationGroup, validatorCssClass, currentValidationStep);                
                }
                else {
                    formIsValid = validateFlexForm(flexFormId, validationGroup, validatorCssClass, currentValidationStep);
                }
                
            }


        })
    });
});