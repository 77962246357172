$(function () {
    $(document).ready(function () {
        //Make this only initialize if there is a dynamic form
        var checkDynamicFormPage = $(".dynamic-form");
        if (checkDynamicFormPage.length > 0) {
            InitializeDynamicForms();
        }
    });
    
    function InitializeDynamicForms() {
        console.log('InitializeDynamicForms()');
        
        var submitButtonEl = $('.dynamic-form-form button[type=submit]')[0];
        var form = $('.dynamic-form-form');
        var formEl = form[0];
        
        form.on('submit', function (form) {
            var dynamicFormId = $(this).attr('dynamic-form-id');
            var recaptchaSiteKey = $(this).attr('recaptcha-site-key');
            var recaptchaAction = $(this).attr('recaptcha-action');
            var validatorCssClass = 'dynamic-form-validation';
            var validationGroup = $(this).attr('dynamic-form-validation-group');
            console.log('Dynamic Form Submit Post - dynamic-form-id=' + dynamicFormId + ' ,dynamic-form-validator-css-class=' + validatorCssClass + ' ,dynamic-form-validation-group=' + validationGroup);
            var currentValidationStep = $('#currentValidationStep').val();
            var formIsValid = validateDynamicForm(dynamicFormId, validationGroup, validatorCssClass, currentValidationStep);
            var dynamicFormSubmitLoadingId = 'dynamic-form-submit-loading-' + dynamicFormId;
            var formType = $(this).attr('method');
            var formUrl = $(this).attr('action');
            var recaptchaToken = '';
            var formSubmitted = false;
            var formData = $(this).serializeArray();

            if (typeof grecaptcha !== "undefined" && grecaptcha && formIsValid) {
                if (recaptchaSiteKey && recaptchaAction) {
                    console.log('grecaptcha sitekey and action');
                    $('#' + dynamicFormSubmitLoadingId).show();
                    grecaptcha.ready(function () {
                        console.log('grecaptcha ready');
                        formSubmitted = true;
                        grecaptcha.execute(recaptchaSiteKey, ({ action: recaptchaAction })).then(function (token) {

                            recaptchaToken = token;
                            console.log('token');
                            console.log(recaptchaToken);

                            if (formIsValid) {
                                formData.push({ name: 'RecaptchaResponse', value: recaptchaToken });
                                console.log('form data ' + recaptchaToken);
                                console.log(formData);
                                $.ajax({
                                    data: formData,
                                    type: formType,
                                    url: formUrl,
                                    async:false,
                                    success: function (responseData, textStatus, xhr) {
                                        console.log('xhr status ');
                                        console.log(xhr.status);
                                        console.log(xhr);
                                        if (xhr.status == 200) {
                                            $('.dynamic-form-submit-success').show();
                                            var FsId = $('.dynamic-form-submit-success').attr('id');
                                            console.log(FsId);
                                            console.log($(FsId));
                                            $('#' + FsId).scrollTop(400);
                                            $('.dynamic-form-submit-success-hide').hide();
                                            $('.dynamic-form-submit-error').hide();
                                            $('#' + dynamicFormSubmitLoadingId).hide();
                                            console.log('flex form submit success');
                                            //console.log(responseData);
                                            formSubmitted = true;

                                            // track success
                                            window.adobeAnalytics.events.formSuccess.track(formEl, submitButtonEl);
                                        }
                                        else {
                                            $('.dynamic-form-submit-error').show();
                                            $('.dynamic-form-submit-success').hide();
                                            $('#' + dynamicFormSubmitLoadingId).hide();
                                            console.log('flex form submit page response error');
                                            console.log(responseData);
                                            
                                            // track error
                                            window.adobeAnalytics.events.formError.track(formEl, submitButtonEl, 'form success response error');
                                        }
                                    },
                                    error: function (responseData) {
                                        $('.dynamic-form-submit-error').show();
                                        $('.dynamic-form-submit-success').hide();
                                        $('#' + dynamicFormSubmitLoadingId).hide();
                                        console.log('flex form submit fail');
                                        console.log(responseData);
                                            
                                        // track error
                                        window.adobeAnalytics.events.formError.track(formEl, submitButtonEl, 'form submission error');
                                    }
                                });
                            }


                        });
                    });
                }
            }
            else if(formIsValid && formSubmitted == false) {

                $('#' + dynamicFormSubmitLoadingId).show();
                var formData = $(this).serializeArray();
                formData.push({ name: 'RecaptchaResponse', value: recaptchaToken });
                console.log('form data ' + recaptchaToken);
                console.log(formData);
                $.ajax({
                    data: formData,
                    type: formType,
                    url: formUrl,
                    success: function (data) {
                        $('.dynamic-form-submit-success').show();
                        var FsId = $('.dynamic-form-submit-success').attr('id');
                        console.log(FsId);
                        console.log($(FsId));
                        $('#' + FsId).scrollTop(400);
                        $('.dynamic-form-submit-success-hide').hide();
                        $('.dynamic-form-submit-error').hide();
                        $('#' + dynamicFormSubmitLoadingId).hide();
                        console.log('flex form submit success');
                        console.log(data);

                        // track success
                        window.adobeAnalytics.events.formSuccess.track(formEl, submitButtonEl);
                    },
                    error: function (data) {
                        $('.dynamic-form-submit-error').show();
                        $('.dynamic-form-submit-success').hide();
                        $('#' + dynamicFormSubmitLoadingId).hide();
                        console.log('flex form submit error');
                        console.log(data);

                        // track error
                        window.adobeAnalytics.events.formError.track(formEl, submitButtonEl, 'form submission error');
                    }
                });
            } else if (!formIsValid) {
                // track error
                window.adobeAnalytics.events.formError.track(formEl, submitButtonEl, 'form input is not valid');
            } else {
                // track error
                window.adobeAnalytics.events.formError.track(formEl, submitButtonEl, 'unspecified form error');
            }
            
            return false;
        })


        $('.dynamic-form-input').on('change input', function () {
            //console.log('on change input ' + $(this).attr('id') + '  ' + $(this).val());
            var dynamicFormId = $(this).attr('dynamic-form-id');
            var formStatus = $('#formStatus' + dynamicFormId).val();
            if (formStatus != '' && formStatus !== 'undefined') {
                var validatorCssClass = 'dynamic-form-validation';//+ formStatus;
                var validationGroup = $('#currentValidationGroup').val();
                //console.log('trigger validation on input - dynamic-form-id=' + dynamicFormId + ' ,validator-css-class=' + validatorCssClass + ' ,validation-group=' + validationGroup);
                var currentValidationStep = $('#currentValidationStep').val();

                var formIsValid = validateDynamicForm(dynamicFormId, validationGroup, validatorCssClass, currentValidationStep);
            }


        })
        //todo: remove this copy and pase
        $('.dynamic-form-validated-input').on('change input', function () {
            //console.log('on change input ' + $(this).attr('id') + '  ' + $(this).val());
            var dynamicFormId = $(this).attr('dynamic-form-id');
            var formStatus = $('#formStatus' + dynamicFormId).val();
            if (formStatus != '' && typeof formStatus !== 'undefined') {
                var validatorCssClass = 'dynamic-form-validation';//+ formStatus;
                var validationGroup = $(this).attr('validation-group');

                var validationStep = $(this).attr('validation-step');

                var currentValidationStep = $('#currentValidationStep').val();
                var validationGroup = $('#currentValidationGroup').val();
                //alert('c.v.s.' + validationGroup);
                //console.log('trigger validation on input - dynamic-form-id=' + dynamicFormId + ' ,validator-css-class=' + validatorCssClass + ' ,validation-group=' + validationGroup);
                var formIsValid = false;
                if (currentValidationStep != '' && typeof currentValidationStep !== 'undefined') {
                    if (formStatus.includes('Step:' + currentValidationStep))
                        formIsValid = validateDynamicForm(dynamicFormId, validationGroup, validatorCssClass, currentValidationStep);
                }
                else {
                    formIsValid = validateDynamicForm(dynamicFormId, validationGroup, validatorCssClass, currentValidationStep);
                }

            }


        });

        console.log('InitializeDynamicForms()_');
    }

    function submitDynamicForm(dynamicFormId) {
        console.log('submitFlexForm(flexFormId)');
        return submitDynamicForm(dynamicFormId, '');
    }
    function submitDynamicForm(dynamicFormId, validationGroup) {
        var validatorCssClass = 'flex-form-validation';
        console.log('submitFlexForm(flexFormId, validationGroup)');
        return validateDynamicForm(dynamicFormId, validationGroup, validatorCssClass);
    }
    function submitDynamicForm(dynamicFormId, validationGroup, validatorCssClass) {
        var validatorCssClass = 'flex-form-validation';
        //console.log('submitFlexForm(flexFormId, validationGroup, validatorCssClass)');
        return validateDynamicForm(dynamicFormId, validationGroup, validatorCssClass, $('currentValidationStep').val());
    }
    function validateDynamicForm(dynamicFormId, validationGroup, validatorCssClass, currentValidationStep) {
        //console.log('validateFlexForm(' + dynamicFormId + ',' + validationGroup + ',' + validatorCssClass, currentValidationStep);
        var isValid = true;
        $('#formStatus' + dynamicFormId).val($('#formStatus' + dynamicFormId).val() + 'validating' + validationGroup);
        var validatorSelectorString = '.' + validatorCssClass + "[dynamic-form-id='" + dynamicFormId + "']";
        var validators = $(validatorSelectorString);
        //console.log(validators);
        validators.each(function (index) {
            var validator = $(this);
            //alert('index ' + index);
            var validationFor = $(this).attr('validation-for');
            var validatorErrorCssClass = $(this).attr('validation-error-css-class');
            //console.log('Validator - validation-for=' + validationFor);
            var requiredFieldValidator = false;
            if (validationFor != '') {
                //var inputField = $('#' + validationFor).not("[type=radio]");
                var inputField = $('#' + validationFor + "[dynamic-form-id='" + dynamicFormId + "']").not("[type=radio]");
                var inputFieldValue = inputField.val();
                console.log('remove error input field input-field: ' + validationFor);
                console.log(inputField);
                var inputErrorCssClass = inputField.attr('validation-error-css-class');
                var inputFieldIsValid = true;
                inputField.removeClass(inputErrorCssClass);
                validator.removeClass(validatorErrorCssClass);
            }

        });
        validators.each(function (index) {
            var validator = $(this);
            var validationFor = $(this).attr('validation-for');
            var validatorErrorCssClass = $(this).attr('validation-error-css-class');
            console.log('Validator - validation-for=' + validationFor);
            var requiredFieldValidator = false;
            if (validationFor != '') {
                var inputField = $('#' + validationFor + "[dynamic-form-id='" + dynamicFormId + "']").not("[type=radio]");
                var isRadioButtonList = $(inputField).attr('is-radio-button-list') == 'true';

                var inputFieldValue = "";
                if (isRadioButtonList) {
                    
                        inputFieldValue = $('input[name=\'' + validationFor + "'][dynamic-form-id='" + dynamicFormId + "']:checked").val();
                        console.log('radio button validator for: ' + validationFor);
                        console.log($('input[name=\'' + validationFor + "'][dynamic-form-id='" + dynamicFormId + "']:checked"));
                        //alert('inputFieldValue: ' + inputFieldValue);
                    
                }
                else {
                    inputFieldValue = inputField.val();
                    console.log('inputField');
                    console.log(inputField);
                }
                console.log('inputFieldValue isRadio=' + isRadioButtonList + ' text=' + inputFieldValue);
                //var validator = $('#' + validationFor);
                var validatorValidationGroup = $(validator).attr('validation-group');
                var validatorValidationStep = $(validator).attr('validation-step');
                //console.log('input-field');
                //console.log(inputField);
                var inputErrorCssClass = inputField.attr('validation-error-css-class');
                var inputFieldIsValid = true;
                //console.log("validationGroup: " + validationGroup + ", validatorValidationGroup: " + validatorValidationGroup);
                //console.log(currentValidationStep);
                if (!currentValidationStep || validatorValidationStep == '' || currentValidationStep == '' || currentValidationStep == validatorValidationStep) {
                    if (validatorValidationGroup == '' || validationGroup == validatorValidationGroup) {

                        if (($(this).attr('validation-is-enabled') != 'false')) {

                            if ($(this).attr('validation-required-field') == 'True') {
                                console.log('required field validator');
                                if (inputFieldValue == '' || typeof inputFieldValue === 'undefined') {
                                    inputFieldIsValid = false;
                                    console.log('required field is valid ' + inputFieldIsValid + ' text =' + inputFieldValue);
                                }
                                else {
                                    console.log('required field is valid (else) ' + inputFieldIsValid + ' text =' + inputFieldValue);
                                }

                            }
                        if ($(this).attr('validation-regular-expression') != '' && inputFieldValue != '') {
                            console.log('regular expression validator - value = ' + inputFieldValue);
                            var reggieText = $(this).attr('validation-regular-expression');
                            var invertRegularExpressionMatchAttr = $(this).attr('invert-regular-expression-match');
                            var invertRegularExpressionMatch = invertRegularExpressionMatchAttr == 'true';
                            reggieText = reggieText.replace('&#39;', '\'').trim();
                            var reggie = new RegExp(reggieText);
                            console.log(reggie);

                            if (invertRegularExpressionMatch) {
                                inputFieldIsValid = !reggie.test(inputFieldValue);
                            }
                            else {
                                inputFieldIsValid = reggie.test(inputFieldValue);
                            }
                        }

                        

                            //compare validator new - start
                            if ($(this).attr('validation-is-enabled') == 'true') {

                                var actualTextField = $(this).attr('validation-for');
                                var compareTextField = $(this).attr('compare-to-field');
                                var actualValue = $('input[id=\'' + actualTextField + '\']').not("[type=radio]").val();
                                var reEnteredValue = $('input[id=\'' + compareTextField + '\']').not("[type=radio]").val();

                                if (actualValue == reEnteredValue) {
                                    inputFieldIsValid = true;
                                }
                                else {
                                    inputFieldIsValid = false;
                                }

                                if (isRadioButtonList) {
                                    if (inputFieldValue == '' || typeof inputFieldValue === 'undefined') {
                                        inputFieldIsValid = false;
                                    }
                                    else {
                                        inputFieldIsValid = true;
                                    }
                                }
                            }
                            //compare validator new - end

                        //console.log('inputFieldIsValid=' + inputFieldIsValid);
                        if (!inputFieldIsValid) {
                            isValid = false;
                            inputField.addClass(inputErrorCssClass);
                            validator.addClass(validatorErrorCssClass);
                        }
                    }
                }
                }
            }

        });
        $('#formStatus' + dynamicFormId).val($('#formStatus' + dynamicFormId).val() + 'validated');
        return isValid;
    }
});



var dynamicWizardResponse = null;
/**
 * 
 */

/**
* 
*/
$(function () {
    $(document).ready(function () {
        //Make this only initialize if there is a dynamic form
        var checkDynamicWizardPage = $(".dynamic-wizard");
        var checkDynamicWizardNoStylePage = $(".dynamic-wizard-nostyle");
        if (checkDynamicWizardPage.length > 0 || checkDynamicWizardNoStylePage.length > 0) {
            InitializeDynamicWizards();
        }

        /**
         * Modal button click - Yes
         */
        $('#u2021-wizardCTAModal .modal-body button:first-of-type').on('click', function (e) {
            var href = $(clickedCTA).attr('link-base-url') + "&type=0";
            window.location.href = href;
        });
        /**
         * Modal button click - No
        */
        $('#u2021-wizardCTAModal .modal-body button:last-of-type').on('click', function (e) {
            var href = $(clickedCTA).attr('link-base-url') + "&type=1";
            window.location.href = href;
        });

        /*var checkContactForm = $('#feedback_contact_form');
        if (checkContactForm.length > 0) {
            initializeFeedbackForm();
            console.log("step1");
        }
        $('.fb_item').on('click', function () {
            updateFbForm($(this));
        });*/


    });
});

function InitializeDynamicWizards() {
    //console.log('InitializeDynamicWizards()');
    var dynamicWizards = $('.dynamic-wizard');
    dynamicWizards.each(function (index) {
        InitializeDynamicWizard(this);
    });
    var dynamicWizardsNoStyle = $('.dynamic-wizard-nostyle');
    dynamicWizardsNoStyle.each(function (index) {
        InitializeDynamicWizard(this);
    });
    dynamicWizardResponse = DynamicWizardResponse();
    $('.wizard-question-answer').on('click', function () {
        dynamicWizardResponse.AnswerClick(this);
    });
    $('.wizard-question-answer').keypress(function (e) {
        console.log(e);
        if (e.keyCode == 13) {
            dynamicWizardResponse.AnswerClick(this);
        }
    });
    $('.wizard-step-back').on('click', function () {
        dynamicWizardResponse.BackButtonClick(this);
    });
    $('.wizard-step-start-over').on('click', function () {
        dynamicWizardResponse.StartOverButtonClick(this);
    });
    var dynamicContent = $('.dynamic-content');
    dynamicContent.each(function (index) {
        InitializeDynamicContent(this);
    });
    //console.log('InitializeDynamicWizards()_');
}
/**
 * 
 */
function hideSteps($steps, isWizard2021) {
    if (isWizard2021) {
        $steps.removeClass('u2021-wizard-visible-slide');
        $steps.closest('.wizard-first-question').hide();
        //console.log('hide is2021');
    }
    else {
        $steps.hide();
        //console.log('hide NOT 2021');
    }
    //console.log($steps);
}
/**
 * 
 */
function showSteps($steps, isWizard2021) {
    if (isWizard2021) {
        $steps.addClass('u2021-wizard-visible-slide');
        $steps.closest('.wizard-first-question').show();
        console.log('show is 2021');
    }
    else {
        $steps.show();
        console.log('show NOT 2021');
    }
    //console.log($steps);
}
/**
 * 
 */
function InitializeDynamicContent(dynamicContent) {
    var dynamicContentTarget = $(dynamicContent).attr('dynamic-content-source');
    var selector = "[dynamic-content-target='" + dynamicContentTarget + "']";
    if ($(selector).length) {
        $(dynamicContent).html($(selector).html());
    }
}
/**
 * 
 */
function InitializeDynamicWizard(dynamicWizard) {
    //console.log('InitializeDynamicWizard()');
    //console.log(dynamicWizard);
    var wizardSteps = $(dynamicWizard).children('.wizard-step');
    if (wizardSteps.length <= 0)
        wizardSteps = $(dynamicWizard).find('.wizard-step-nostyle');
    var wizardResults = $(dynamicWizard).children('.wizard-result');
    //console.log('wizardSteps');
    //console.log(wizardSteps);
    if (wizardResults.length <= 0)
        wizardResults = $(dynamicWizard).find('.wizard-result-nostyle');
    //console.log('wizardNSSteps');
    //console.log(wizardSteps);
    var wizard2021Attribute = $(dynamicWizard).attr('wizard-2021');
    var isWizard2021 = wizard2021Attribute == 'true';
    hideSteps($(wizardSteps), isWizard2021);
    hideSteps($(wizardResults), isWizard2021);
    showSteps($(wizardSteps).first(), isWizard2021);
    //console.log('InitializeDynamicWizard()_');
}
/**
 * 
 */
function DynamicWizardQuestionResponse(questionText, answerText) {
    var ret = new Object;
    ret.QuestionText = questionText;
    ret.AnswerText = answerText;
    return ret;
}
/**
 * 
 */
function DynamicWizardResponse() {
    this.CompletedSteps = [];
    this.CompletedQuestionAnswers = [];
    this.AddQuestionAnswer = function (questionText, answerText) {
        var questionResponse = DynamicWizardQuestionResponse();
        questionResponse.QuestionText = questionText;
        questionResponse.AnswerText = answerText;
        CompletedQuestionAnswers.push(DynamicWizardQuestionResponse(questionText, answerText));
    }
    this.GetQuerystring = function () {
        var ret = "";
        for (var i = 0; i < CompletedQuestionAnswers.length; i++) {
            if (CompletedQuestionAnswers[i].QuestionText != '' && CompletedQuestionAnswers[i].AnswerText != '') {
                if (i > 0) {
                    ret += '&';
                }
                ret += CompletedQuestionAnswers[i].QuestionText + '=' + CompletedQuestionAnswers[i].AnswerText;
            }

        }
        // ret = ret.replace('&type=0', ''); // Not sure the purpose...
        return ret;
    }
    /**
     * 
     */
    this.BackButtonClick = function (element) {
        var wizardStepId = $(element).attr('wizard-step-id');
        var lastStepId = this.CompletedSteps.pop();

        CompletedQuestionAnswers.pop();

        hideSteps($('#wizard-step-' + wizardStepId));
        hideSteps($('#wizard-result-' + wizardStepId));
        showSteps($('#wizard-step-' + lastStepId));
    };
    /**
     * 
     */
    this.AnswerClick = function (element) {
        var isFbForm = $("#feedback_contact_form").length > 0;
        var wizardStepId = $(element).attr('wizard-step-id');
        var wizardStepDepth = parseInt($(element).attr('wizard-step-depth'));
        var wizardQuestionFieldName = $(element).attr('wizard-question-field-name');
        var wizardQuestionAnswerValue = $(element).attr('wizard-question-answer-value');
        var wizardQuestionAnswerNextStepId = $(element).attr('wizard-question-answer-next-step-id');
        var wizardSupportEmail = $(element).attr('wizard-question-answer-support-email');
        var hideFieldIfSelected = $(element).attr('hide-field-if-selected');
        var showFieldIfSelected = $(element).attr('show-field-if-selected');
        
        var supportEmailId = "SupportEmail";
        //console.log(isFbForm);
        var wizardId = $(element).attr('wizard-id');
        var wizard2021Attribute = $(element).attr('wizard-2021');
        var hidePastSteps = $(element).attr('wizard-2021');
        var isWizard2021 = wizard2021Attribute == 'true';

        if (hideFieldIfSelected == 'none' && wizardQuestionFieldName == 'Level1') {
            $('.dynamicallyhidden').removeClass('hidden');
            $('.dynamicallyhidden').removeClass('dynamicallyhidden');
            $('.dynamicallyhiddenValidator').attr('validation-is-enabled', 'true');
            $('.dynamicallyhiddenValidator').removeClass('dynamicallyhiddenValidator');

        }
        else if (!!hideFieldIfSelected && hideFieldIfSelected != 'none' && wizardQuestionFieldName == 'Level1') {

            var levelOneHideListArray = hideFieldIfSelected.split("|");

            for (i = 0; i < levelOneHideListArray.length; i++) {
                

                $('div[div-id=\'' + levelOneHideListArray[i] + '\']').find("input").removeAttr("checked");

                $('div[div-id=\'' + levelOneHideListArray[i] + '\']').addClass('hidden');
                $('div[div-id=\'' + levelOneHideListArray[i] + '\']').addClass('dynamicallyhidden');
                $('div[validation-for=\'' + levelOneHideListArray[i] + '\']').attr('validation-is-enabled', 'false');
                $('div[validation-for=\'' + levelOneHideListArray[i] + '\']').addClass('dynamicallyhiddenValidator');
            }
            
        }

        if (showFieldIfSelected != 'none' && wizardQuestionFieldName == 'Level1') {
            $('div[div-id=\'' + showFieldIfSelected + '\']').removeClass('hidden');
            $('div[div-id=\'' + showFieldIfSelected + '\']').addClass('dynamicallysfhidden');
            $('div[validation-for=\'' + showFieldIfSelected + '\']').attr('validation-is-enabled', 'true');
            $('div[validation-for=\'' + showFieldIfSelected + '\']').addClass('dynamicallysfhiddenValidator');
        }
        else if (showFieldIfSelected == 'none' && wizardQuestionFieldName == 'Level1') {
            $('.dynamicallysfhiddenValidator').attr('validation-is-enabled', 'false');
            $('.dynamicallysfhiddenValidator').removeClass('dynamicallysfhiddenValidator');
            $('.dynamicallysfhidden').addClass('hidden');
            $('.dynamicallysfhidden').removeClass('dynamicallysfhidden');

        }
        


        $('input[dynamic-wizard-id=\'' + wizardId + '\'][name=\'' + wizardQuestionFieldName + '\']').val(wizardQuestionAnswerValue);
        if (wizardQuestionFieldName == 'Level1' && wizardSupportEmail != '') {
            $('input[id=\'' + supportEmailId + '\']').val(wizardSupportEmail);
        }
        //console.log('Answer Click wizardStepId:' + wizardStepId + ' wizardQuestionFieldName:' + wizardQuestionFieldName + ' wizardQuestionAnswerValue:' + wizardQuestionAnswerValue + 'wizardQuestionAnswerNextStepId: ' + wizardQuestionAnswerNextStepId);
        if (!isFbForm) {
            hideSteps($('#wizard-step-' + wizardStepId), isWizard2021);
        } else {
            updateFbForm($(element));
            $('input[dynamic-wizard-id=\'' + wizardId + '\'][name=\'' + wizardQuestionFieldName + '\']').val(wizardQuestionAnswerValue);
        }
        showSteps($('#wizard-step-' + wizardQuestionAnswerNextStepId), isWizard2021);
        showSteps($('#wizard-result-' + wizardQuestionAnswerNextStepId), isWizard2021);
        $('.wizard-step-nostyle[wizard-id=' + wizardId + ']').each(function () {
            //console.log(compareWizardStepDepth + ' > ' + wizardStepDepth);
            var compareWizardStepDepth = parseInt($(this).attr('wizard-step-depth'));
            if (compareWizardStepDepth > wizardStepDepth) {
                var wizardStepQuestionFieldName = $(this).attr('wizard-step-question-field-name');
                $('input[dynamic-wizard-id=\'' + wizardId + '\'][name=\'' + wizardStepQuestionFieldName + '\']').val('');
               // console.log('clear' + wizardStepQuestionFieldName);
            }
        });
        $('input[dynamic-wizard-id=\'' + wizardId + '\'][name=\'' + wizardQuestionFieldName + '\']').val(wizardQuestionAnswerValue);
        CompletedSteps.push(wizardStepId);
        CompletedQuestionAnswers.push(DynamicWizardQuestionResponse(wizardQuestionFieldName, wizardQuestionAnswerValue));
        var querystring = this.GetQuerystring();
        $('.card-comparison-apply-now-button').each(function () {
            var linkBaseUrl = $(this).attr('link-base-url');
            var querystringPrefix = "?";
            if (linkBaseUrl.indexOf('?' > 0)) {
                if (querystring.indexOf('&' > 0)) {
                    querystringPrefix = '';
                }
                else {
                    querystringPrefix = '&';
                }
            }
            $(this).attr('href', linkBaseUrl + querystringPrefix + querystring);
        });

        return true;
    }
    /**
     * 
     */
    

    $('input[type=radio][name=PreferredContactMethod]').change(function () {
        var showList = $(this).attr('field-to-activate');
        var showListArray = showList.split("|");
        var hideList = $(this).attr('field-to-hide');
        var hideListArray = hideList.split("|");

        for (i = 0; i < showListArray.length; i++) {
            $('div[validation-for=\'' + showListArray[i] + '\']').attr('validation-is-enabled', 'true');
            $('div[div-id=\'' + showListArray[i] + '\']').attr('class', 'dynamic-form-field-container radioitems');
        }

        for (j = 0; j < hideListArray.length; j++) {
            $('div[validation-for=\'' + hideListArray[j] + '\']').attr('validation-is-enabled', 'false');
            $('div[div-id=\'' + hideListArray[j] + '\']').attr('class', 'dynamic-form-field-container radioitems hidden');
        }
    });

    this.StartOverButtonClick = function (element) {
        var wizardStepId = $(element).attr('wizard-step-id');
        while (CompletedSteps.length > 0) {
            lastStepId = CompletedSteps.pop();
        }
        while (CompletedQuestionAnswers.length > 0) {
            CompletedQuestionAnswers.pop();
        }

        hideSteps($('#wizard-step-' + wizardStepId));
        hideSteps($('#wizard-result-' + wizardStepId));
        showSteps($('#wizard-step-' + lastStepId));
    }
    return this;
}
function initializeFeedbackForm() {
    console.log("step2");
    $('.fb_items_row').each(function () {
        if ($(this).attr('wizard-step-depth') == '1') {
            $(this).show();
        } else {
            $(this).hide();
        }
    });
}
function updateFbForm(e) {
    var Fb_item_step_id = e.attr('wizard-step-id');
    var Fb_item_question_answer_id = e.attr('wizard-question-answer-id');
    var CurrentLevelId = $('#wizard-step-' + Fb_item_step_id).attr('wizard-step-id');
    var CurrentWizardDepth = $('#wizard-step-' + Fb_item_step_id).attr('wizard-step-depth');
    var NextWizardStepId = e.attr('wizard-question-answer-next-step-id');
    var wizardQuestionFieldName = e.attr('wizard-question-field-name');
    $('#wizard-step-' + Fb_item_step_id + ' .wizard-question-answer').each(function () {
        if ($(this).attr('wizard-question-answer-id') == Fb_item_question_answer_id) {
            $(this).addClass('active');
            $('html, body').animate({
                scrollTop: $(this).offset().top
            }, 800, function () {
            });
        }
        else {
            $(this).removeClass('active');
        }
    });
    $('.wizard-step-nostyle').each(function () {
        if (parseInt($(this).attr('wizard-step-depth')) >= (parseInt(CurrentWizardDepth) + 1)) {
            if ($(this).attr('wizard-step-id') == NextWizardStepId) {
                $(this).show();
            }
            else {
                $(this).hide();
                $('input[dynamic-wizard-id=\'' + e.attr('wizard-id') + '\'][name=\'' + wizardQuestionFieldName + '\']').val();
            }
        } else if ($(this).hasClass("fb_form")) {
            $(this).hide();
        }
    });
    $('.dynamic-form-input').each(function (i) {
        if ($(this).attr('name') == 'Level' + i) {
            if ($(this).attr('name').split('Level')[1] > parseInt(e.attr('wizard-step-depth'))) {
                $(this).val('');
            }
        }
    });
}

