; (function ($) {
    'use strict';

    $.BranchLocator = {

        extmap: null,
        userpin: null,
        markers: [],
        userPinDropped: false,
        userPos: null,
        yourLocationMarkerUrl: null,
        pinIconUrl: null,
        pinActiveIconUrl: null,
        checkedFilters: 1,
        locationSettingsKnown: false,
        boundsReady: false,
        zoomLevel: 11,
        dragged: false,
        limitedCoOp: false,
        suppressZoomSearch: false,
        geocoder: null,

        initMap: function () {
            var self = this;

            //debugger;
            var mapElem = document.getElementById("map");
            if (!mapElem)
                return;

            // pin the global var set in cshtml to this object var
            self.yourLocationMarkerUrl = g1UserLocationMarkerUrl;
            self.pinIconUrl = golden1PinIconUrl;
            self.pinActiveIconUrl = golden1PinActiveIconUrl;

            // Set map options and default location to sacramento and zoom level to 12
            var mapOptions = {
                zoom: 11,
                center: {
                    lat: 38.599899632629246,
                    lng: -121.44860649999998
                },
                disableDefaultUI: true,
                zoomControl: true,
                panControl: true,
                scaleControl: true,
                rotateControl: true
            };

            self.extmap = new google.maps.Map(mapElem, mapOptions);
            self.extmap.setCenter(mapOptions.center);
            self.geocoder = new google.maps.Geocoder();

            // setup checkbox filters
            var $g1Filter = $(".locationCheck");
            $g1Filter.on("change", function () {

                var isChecked = $(this).is(":checked");
                if (isChecked)
                    self.checkedFilters++;
                else
                    self.checkedFilters--;

                // get new locations
                self.doLocationSearch(false, true, false, true);
            });

            var mapBtn = $(".btn-map-switch");
            $(mapBtn).on("click", function (e) {

                var $infoMessage = $('#locator-info-message');
                $infoMessage.empty();
                $infoMessage.addClass('d-none');
                var mapPanel = $(this).attr("aria-controls");

                if (!$(this).hasClass("btn-active")) {
                    $(mapBtn).removeClass("btn-active").attr("aria-selected", "false");
                    $(this).addClass("btn-active").attr("aria-selected", "true");
                    $("#" + mapPanel).css('display', 'flex').attr("hidden", false);
                    

                    if (mapPanel == "map-view") {
                        $('#location-view').hide().attr('hidden', true);
                    }
                    else {
                        $('#map-view').hide().attr('hidden', true);
                    }
                }

                e.preventDefault();
            });

            var filterbyMapBtn = $("#filterbyMap");
            $(filterbyMapBtn).on("click", function (e) {
                $("#locations-filter").toggle();

                var $infoMessage = $('#locator-info-message');
                $infoMessage.empty();
                $infoMessage.addClass('d-none');

                if ($(filterbyMapBtn).hasClass("btnFilterActive")) {
                    $(filterbyMapBtn).removeClass("btnFilterActive");
                    $(filterbyMapBtn).attr('aria-expanded', false);
                }
                else {
                    $(filterbyMapBtn).addClass("btnFilterActive");
                    $(filterbyMapBtn).attr('aria-expanded', true);
                }
            });

            //START hack to set scale to miles/imperial instead of km/metric:
            window.setTimeout(function () {
                var spn = mapElem.getElementsByClassName("gm-style-cc");
                for (var i in spn) {
                    //look for km or m in innerText via regex https://regexr.com/3hiqa
                    if ((/\d\s?(km|(m\b))/g).test(spn[i].innerText)) {
                        spn[i].click();
                    }
                }
            }, 1000);
            //END hack to set scale to miles/imperial instead of km/metric

            self.extmap.addListener('dragend', function () {
                // dont let bounds change on a drag; not a pleasant UX
                self.dragged = true;                
            });

            self.extmap.addListener('idle', function () {
                // after dragging, wait until idle to do a search
                // this helps when users are panning quickly
                if (self.dragged === true) {
                    self.dragged = false;
                    self.doLocationSearch(false, false, false, false);
                }
            });

            self.extmap.addListener('zoom_changed', function () {
                var newzoom = self.extmap.getZoom();
                var zoomedOut = newzoom < self.zoomLevel;
                self.zoomLevel = newzoom;

                if (self.suppressZoomSearch === false) {
                    // on do a search zoom if zooming out or zooming in for finer co-op locations
                    if (zoomedOut || self.limitedCoOp) {
                        self.limitedCoOp = false;
                        self.doLocationSearch(false, false, false, false);
                    }
                }
                else {
                    // turn off supression for next zoom
                    self.suppressZoomSearch = false;
                }
            });

            self.extmap.addListener('click', function () {
                self.resetMapMarkersIcons();
            });

            self.extmap.addListener('bounds_changed', function () {
                // looking for the first bounds changed so we can init the first search
                if (self.boundsReady == false) {

                    self.boundsReady = true;
                    // perform initial search
                    self.doLocationSearch(true, true, false, true); // prompt for position after initial search
                }                
            });

            //Autocomplete location
            var input = document.getElementById("enterCurrentLoc");

            var options = {
                componentRestrictions: { country: 'us' },
                fields: ['name','formatted_address', 'place_id'],
                types: ['(regions)']
            };

            self.autocomplete = new google.maps.places.Autocomplete(input, options);
            
            self.autocomplete.addListener("place_changed", function () {
                
                var $infoMessage = $('#locator-info-message');
                $infoMessage.empty();
                $infoMessage.addClass('d-none');

                var place = this.getPlace();
                // check if user enters text and hits enter without selecting
                if (place.place_id) {

                    // valid place actually selected
                    $("#form-error-other").hide();
                    $("#enterCurrentLoc-error").hide();
                    self.geoCodePlace(place.formatted_address);
                }
                else {
                    // user pressed enter; lets do our own search here using a service
                    // and return the first result
                    var acService = new google.maps.places.AutocompleteService();
                    var request = {
                        input: place.name,
                        componentRestrictions: { country: 'us' },
                        fields: ['description'],
                        types: ['(regions)']
                    };
                    acService.getPlacePredictions(request, function (data) {
                        console.log(data);
                        if (data.length > 0) {
                            var topHit = data[0];
                            // update the text box
                            if ($.trim(topHit.description)) {
                                $("#enterCurrentLoc").val(topHit.description);
                                self.geoCodePlace(topHit.description);
                            }
                        }
                    });
                }
            });

            $("#resetMap").on("click", function (e) {
                e.preventDefault();

                var $infoMessage = $('#locator-info-message');
                var clearMessage = $infoMessage.data('locator-clear-message');
                $infoMessage.empty();
                $infoMessage.append(`<p>${clearMessage}</p>`);
                $infoMessage.removeClass('d-none');

                $("#enterCurrentLoc").val("");
                $("#form-error-other").hide();
                $("#enterCurrentLoc-error").hide();

                // reset check boxes
                $("#golden-branches").prop("checked", true);
                $("#golden-atms").prop("checked", true);
                $("#golden-home-centers").prop("checked", false);
                $("#coop-branches").prop("checked", false);
                $("#coop-atms").prop("checked", false);
                self.checkedFilters = 1;

                // TODO: reset sort based on userpin

                var tryCenterOnFirst = true;
                if (self.userPos && typeof (self.userPos) !== 'undefined') {
                    tryCenterOnFirst = false;
                    self.extmap.setCenter(self.userPos);
                }
                else
                    self.extmap.setCenter({ lat: 38.599899632629246, lng: -121.44860649999998 })

                self.suppressZoomSearch = true;
                self.extmap.setZoom(11);
                self.doLocationSearch(false, tryCenterOnFirst, true, true);
            });
        },

        onPositionReceived: function (position) {
            var self = this;
         
            // this only gets fired after successful callback;
            // will not fire immediately after user accepts

            var userPos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            self.extmap.setCenter(userPos);

            // after setting new location on map, drop a user pin
            self.setUserPin(userPos);

            // Create the DIV to hold the control and call the CenterControl()
            // constructor passing in this DIV.
            var centerControlDiv = document.createElement('div');
            var centerCtrl = new self.centerControl(centerControlDiv);

            // Setup the click event listeners: simply set the map to user location
            centerCtrl.addEventListener('click', function () {
                $("#enterCurrentLoc").val("");
                self.extmap.setCenter(userPos);
                self.suppressZoomSearch = true;
                self.extmap.setZoom(11);
                self.doLocationSearch(false, false, true, false);
            });

            centerControlDiv.index = 1;
            self.extmap.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);

            // searched based on new location
            self.doLocationSearch(false, false, true, true);
        },

        geoCodePlace: function (formatted_address) {
            var self = this;            
            var georequest = {
                'address': formatted_address,
                componentRestrictions: { country: 'us' }
            };

            self.geocoder.geocode(georequest, function (results, status) {
                if (status == 'OK') {
                    var topHit = results[0];                                        
                    self.finishPlacesSearch(topHit);
                } else {
                    $("#form-error-other").text("We were unable to find that location").show();
                    console.error(status);
                }
            });
        },

        resetMapMarkersIcons: function () {
            var self = this;
            $.each(self.markers, function (i, marker) {
                var originalIcon = {
                    url: self.pinIconUrl,
                    scaledSize: new google.maps.Size(20, 28),
                    labelOrigin: new google.maps.Point(10, 10)
                };
                var label = marker.getLabel();
                label.fontWeight = 'normal';
                label.fontSize = '13px';

                marker.setLabel(label);
                marker.setIcon(originalIcon);
            });
        },

        finishPlacesSearch: function (topHit) {

            var self = this;

            var newPos = {
                lat: topHit.geometry.location.lat(),
                lng: topHit.geometry.location.lng()
            };

            self.extmap.setCenter(newPos);
            self.suppressZoomSearch = true;
            self.extmap.setZoom(11);
            self.doLocationSearch(false, true, true, true);
        },

        setUserPin: function (pos) {
            var self = this;

            self.userPinDropped = true;

            self.userPos = pos;

            var image = {
                url: self.yourLocationMarkerUrl,                
                scaledSize: new google.maps.Size(20, 28)
            };

            //Place marker at user location
            self.userpin = new google.maps.Marker({
                position: pos,
                icon: image, //set the custom marker
                map: self.extmap,
                animation: google.maps.Animation.DROP,
                id: 'geolocation'
            });
        },

        clearMarkers: function () {
            var self = this;

            for (var i = 0; i < self.markers.length; i++) {
                self.markers[i].setMap(null);
            }
        },

        deleteMarkers: function () {
            var self = this;

            self.clearMarkers();
            self.markers = [];
        },

        showLocationInfoWindow: function ($loc) {
            var self = this;
            var titleToFind = $loc.attr("data-pin-id");

            // find map marker in markers array with the same title
            var markerToClick;
            $.each(self.markers, function (i, marker) {
                if (marker.id === titleToFind) {
                    markerToClick = self.markers[i];
                }
            });

            new google.maps.event.trigger(markerToClick, 'click');
        },

        doLocationSearch: function (promptLocationOnDone, tryCenterOnFirst, animateMarkers, autoScrollMobile) {
            var self = this;

            self.deleteMarkers(); // always clear out all existing markers
            $("#locations-list").empty(); //Clear locations list before adding new data

            var bounds = self.extmap.getBounds();
            if (!bounds || typeof (bounds) === "undefined") {
                console.warn('No bounds for search');
                return;
            }

            // TODO: disable checkboxes? textbox? zoom? pan?
            $("#locations-list").html("<h4>Loading locations ...</h4>");
            $("#map-loading").show();
            $("#form-error-other").hide();

            // on mobile scroll down to the mobile switch
            if (autoScrollMobile === true && $("#mobile-switch").is(":visible"))
                $("#last-type-filter").get(0).scrollIntoView();

            var nelat = bounds.getNorthEast().lat();
            var nelng = bounds.getNorthEast().lng();
            var swlat = bounds.getSouthWest().lat();
            var swlng = bounds.getSouthWest().lng();
            var mapcenterlat = self.extmap.getCenter().lat();
            var mapcenterlng = self.extmap.getCenter().lng();

            // user pos (if ready)
            var userlat = null, userlng = null;
            if (self.userPos && typeof (self.userPos) !== 'undefined') {
                userlat = self.userPos.lat;
                userlng = self.userPos.lng;
            }

            //var d = new Date();
            //var n = d.getTime();
            //console.log(n + ': searching ...');

            $.ajax({
                url: '/api/BranchLocator/GetLocations',
                type: 'POST',
                dataType: 'json',
                data: {
                    "golden1branches": $("#golden-branches").is(":checked"),
                    "golden1homecenters": $("#golden-home-centers").is(":checked"),
                    "golden1atm": $("#golden-atms").is(":checked"),
                    "Golden1FinancialResourceCenter": $("#golden-fin-res-centers").is(":checked"),
                    "sharedbranches": $("#coop-branches").is(":checked"),
                    "sharedatm": $("#coop-atms").is(":checked"),
                    "swlat": swlat,
                    "swlng": swlng,
                    "nelat": nelat,
                    "nelng": nelng,
                    "centerlat": mapcenterlat,
                    "centerlng": mapcenterlng,
                    "userlat": userlat,
                    "userlng": userlng
                }
            })
            .done(function (data) {
                self.processLocationResults(data, tryCenterOnFirst, animateMarkers);
            })
            .fail(function (xhr, status, error) {
                $(".location-list").html("<h4>Please try again</h4>");
                console.error(error);
            })
            .always(function () {
                $("#map-loading").hide();
                if (promptLocationOnDone) {
                    self.retrieveUserLocation();
                }
            });
        },

        retrieveUserLocation: function () {
            var self = this;
            if (navigator.geolocation) {
                setTimeout(function () {
                    if (!self.locationSettingsKnown)
                        $("#location-message").toggleClass("d-none", false);
                }, 500);

                navigator.geolocation.getCurrentPosition(function (position) {
                    self.locationSettingsKnown = true;
                    $("#location-message").toggleClass("d-none", true);
                    self.onPositionReceived(position);
                }, function () {
                    self.locationSettingsKnown = true;
                    $("#location-message").toggleClass("d-none", true);
                    $("#form-error-other").text("We were unable to pinpoint your location.").show();
                });
            } else {
                self.locationSettingsKnown = true;
                $("#location-message").toggleClass("d-none", true);
                // Browser doesn't support Geolocation
                $("#form-error-other").text("Your browser doesn't support location services.").show();
            }
        },

        processLocationResults: function (data, tryCenterOnFirst, animateMarkers) {
            var self = this;

            //console.log(data.length + ' Locations received');

            $("#map-loading").hide();
            $(".location-list").empty(); //Clear loading message

            //Check is we have data first
            if (!$.trim(data) || !$.trim(data.locations)) { //if there is no data
                //Setup side HTML
                var locationList = '<div class="loc loc-error">' +
                    '<p>Sorry, no results found in this location</p>' +
                    '</div>';
                $(".location-list").append(locationList);
                //console.log('no data returned');
            }

            //console.log(data.length + ' locations returned');

            // Create Bound array
            // based on events we are watching, fitting the map to the new
            // bounds is causing unexpected results; need to revisit if its desired behavior
            //var latlngbounds = new google.maps.LatLngBounds();

            var sharedLocationCount = 0;
            var visibleMarkerCount = 0;
            var firstPos = null;
            var i = 0;

            var currentBounds = self.extmap.getBounds();

            var doAnimate = animateMarkers && data.locations.length <= 25;

            // this needs to be optimized; its a pretty heavy loop to hand on to client
            $.each(data.locations, function (key, data) {

                // Get Lat/Lng for each location
                var locLatLng = new google.maps.LatLng(data.lat, data.lng);

                if (i === 0) {
                    firstPos = locLatLng;
                }

                if (data.category === 'A' || data.category === 'S')
                    sharedLocationCount++;

                // Add to Bound
                //latlngbounds.extend(locLatLng);

                // Creating a marker and putting it on the map
                var marker = self.getLocationMarker(data, locLatLng, doAnimate);
                if (doAnimate)
                    window.setTimeout(function () { self.markers.push(marker); }, i * 200);
                else
                    self.markers.push(marker);
                //marker.setMap(self.extmap);

                if (currentBounds.contains(marker.getPosition()))
                    visibleMarkerCount++;

                //Map Event listener and Info Window display
                (function (marker, data) {
                    google.maps.event.addListener(marker, "click", function (e) {
                        self.resetMapMarkersIcons();
                        var image = {
                            url: self.pinActiveIconUrl,
                            scaledSize: new google.maps.Size(40, 56),
                            labelOrigin: new google.maps.Point(20, 20)
                        };
                        var label = marker.getLabel();
                        label.fontWeight = 'bold';
                        label.fontSize = '17px';

                        marker.setIcon(image);
                        marker.setLabel(label);
                        self.extmap.setCenter(marker.getPosition());

                        var activeItemId = ".location-list .loc[data-pin-id='" + data.id + "']";
                        $(".location-list .loc").removeClass("active-loc-item");

                        if ($(activeItemId).length > 0) {
                            $(activeItemId).addClass("active-loc-item");
                            $(activeItemId)[0].scrollIntoView();
                        }
                    });
                })(marker, data);

                var features = '';
                var holidays = '';
                var homeLoansAppButton = '';

                if (data.features.length > 0) {
                    features = '<div class="loc-features loc-features-list"><label>Features</label><ul>';
                    for (var i = 0; i < data.features.length; i++) {
                        features += '<li>' + data.features[i] + '</li>';
                    }
                    features += '</ul></div>';
                }

                if (data.holidays.length > 0) {
                    holidays = '<div class="loc-holidays">';
                    holidays += data.holidays;
                    holidays += '</div>';
                }

                if (data.homeLoansAppUrl && data.homeLoansAppUrl.length > 0) {
                    homeLoansAppButton = '<a href="' + data.homeLoansAppUrl + '" class="btn btn-primary btn-sm btn-desktop" target="_blank">Make Appointment</a>';
                }

                //Setup side HTML
                var distancePoint = data.useCenterDistance === true ? data.distanceToCenter.toFixed(1) : data.distanceToUser.toFixed(1)
                var locationList = '<div class="loc" data-pin-id="' + data.id + '">' +
                    '<div class="loc-layout-row"><div class="location-title">' + `<h3>${data.title}</h3>` + '<div class="loc-title-cat">' + data.locationType + '</div></div>' +
                    '<div class="loc-marker"><div class="loc-dist">' + distancePoint + '</div><div class="loc-dist-miles">miles</div></div></div>' +
                    '<div class="loc-layout-row"><div class="loc-address"><label>Address</label><a href="https://maps.google.com/?daddr=' + data.address + ' ' + data.city + ' ' + data.state + ' ' + data.zip + '" target="_blank">' + data.address + '</a><img src="' + data.imageUrl + '" alt=""></div>' +
                    '<div class="loc-hours loc-hours-list ' + (data.hours.length > 0 ? 'd-block' : 'd-none') + '"><label>Hours</label>' +
                    '<div>' + data.hours.replace(/\\n/g, '<br />') + '</div>' + holidays + '</div>' + features + '</div>' +
                    homeLoansAppButton +
                    (data.hasApp ? '<a href="' + data.branchAppUrl + '" class="btn btn-primary btn-sm btn-desktop" target="_blank">Make Appointment</a>' : '') +
                    '</div>';

                $(".location-list").append(locationList);

                i++;
            });

            $(".location-list .loc").on('click', function () {
                if (!$(this).parent().hasClass('list-view-active')) {
                    self.showLocationInfoWindow($(this));
                    $(".location-list .loc").removeClass("active-loc-item");
                    $(this).addClass("active-loc-item");
                    $("#btn-switch-map").trigger("click");

                    var id = 'mobile-switch';
                    var element = document.getElementById(id);
                    element.scrollIntoView(true);
                    setTimeout(function () {
                        window.scrollBy(0, -70)
                    }, 500);
                }
            });

            if (data.limitedCoOp || sharedLocationCount === 100) {
                // all coop locations on visible portion of map arent displayed
                self.limitedCoOp = true;
                $("#form-error-other").text("Limited Co-Op results. Zoom in for finer results.").show();
            }

            if (data.switchedToCoOpATM || data.switchedToCoOpBranch) {
                $("#form-error-other").text("No Golden 1 locations found. Co-Op matches were automatically included.").show();
                if (data.switchedToCoOpATM) {
                    $("#coop-atms").prop("checked", true);
                    self.checkedFilters++;
                }

                if (data.switchedToCoOpBranch) {
                    $("#coop-branches").prop("checked", true);
                    self.checkedFilters++;
                }
            }

            var resultsCount = data.locations.length;
            //console.info(resultsCount + ' results');
            if (resultsCount === 0)
                return;

            if (visibleMarkerCount <= 0) {
                //console.warn('Zero visible markers');
                if (tryCenterOnFirst) {
                    //console.info('recentering on first marker');
                    self.extmap.setCenter(firstPos);
                }
                else {
                    //console.info(visibleMarkerCount + ' visible markers');
                }
            }
        },

        getLocationMarker: function (data, locLatLng, animate) {
            var self = this;

            var image = {
                url: self.pinIconUrl,
                scaledSize: new google.maps.Size(20, 28),
                labelOrigin: new google.maps.Point(10, 10)
            };

            return new google.maps.Marker({
                map: self.extmap,
                position: locLatLng,
                title: data.title,
                label: {
                    text: data.order,
                    color: '#ffffff',
                    fontSize: '13px',
                    fontWeight: 'normal'
                },
                icon: image,
                animation: (animate ? google.maps.Animation.DROP : null),
                id: data.id
            });
        },

        centerControl: function (controlDiv) {

            // Set CSS for the control border.
            var controlUI = document.createElement('div');
            controlUI.style.backgroundColor = '#fff';
            controlUI.style.border = '2px solid #fff';
            controlUI.style.borderRadius = '3px';
            controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
            controlUI.style.cursor = 'pointer';
            controlUI.style.marginBottom = '22px';
            controlUI.style.textAlign = 'center';
            controlUI.title = 'Click to recenter the map on your location';
            controlDiv.appendChild(controlUI);

            // Set CSS for the control interior.
            var controlText = document.createElement('div');
            controlText.style.color = 'rgb(25,25,25)';
            controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
            controlText.style.fontSize = '16px';
            controlText.style.lineHeight = '38px';
            controlText.style.paddingLeft = '10px';
            controlText.style.paddingRight = '10px';
            controlText.innerHTML = '<img src="' + g1UserLocationMarkerUrl + '" width="15" height="20" />&nbsp;&nbsp;My Location';
            controlUI.appendChild(controlText);

            return controlUI;
        }

    };

})(jQuery);