
var scrollPagination = (function() {
    var fn = {};

    if (!Object.create) {
        Object.create = function(prototype) {
            var obj = function() {
                return undefined;
            };
            obj.prototype = prototype;
            return new obj;
        };
    }

    fn = {
        init: function(contentType, loadMoreLink) {
            var self = this;

            self.contentType = contentType;
            self.loadMoreLink = loadMoreLink;
            self.pageNumber = 1;
            self.loadingResults = false;
            self.lastScrollPosition = 0;

            self.setAttributesForType();

            if (self.containerName) {
                $(window).bind('scroll', function() {
                    var currentPosition = $(this).scrollTop();
                    if (currentPosition > self.lastScrollPosition) {
                        if (!self.loadingResults && $(window).scrollTop() >= ($(self.containerName).offset().top + $(self.containerName).outerHeight() - window.innerHeight)) {
                            self.getNextPageContent();
                        }
                    }
                    self.lastScrollPosition = currentPosition;
                });
            }

            $(document).on('click', self.loadMoreLink, function(e) {
                self.getNextPageContent();
                e.stopPropagation();
                return false;
            });
        },

        setAttributesForType: function() {
            var self = this;

            if (self.contentType === 'news') {
                self.containerName = '.news-wrapper';
                self.APIUrl = '/api/NewsServices/NewsListAPI';
            } else if (self.contentType === 'events') {
                self.containerName = '.event-list-wrapper';
                self.APIUrl = '/api/EventsServices/EventsListAPI';
            }
        },

        setUrlParams: function() {
            var self = this;

            self.urlParams = {};
            self.urlParams.pageNo = self.pageNumber;

            if (self.contentType === 'news') {
                self.urlParams.yearVal = $('.news-filter li.active').attr('data-year');
            }
            self.urlParams.pageUrl = window.location.href;
        },

        getNextPageContent: function() {
            var self = this;
            var loadMoreContainer = '';

            if (self.pageNumber) {
                self.pageNumber++ ;
                self.loadingResults = true;

                self.setUrlParams();
                loadMoreContainer = $('.load-more-container');
                $('.load-more-container').remove();
                $(self.containerName).append('<div class="loading-container"><img src="/assets/images/ajax-process-icon.gif"></div>');
                $.ajax({
                    method: 'GET',
                    url : self.APIUrl,
                    data: self.urlParams,
                    caches: false,
                    success: function(data) {
                        if (data.Success) {
                            self.updateMarkup(data);
                            loadMoreContainer.insertAfter($(self.containerName));
                        } else {
                            self.pageNumber = 0;
                        }
                    },
                    error: function() {
                        self.pageNumber = 0;
                    },
                    complete: function() {
                        self.loadingResults = false;
                        $(self.containerName + ' .loading-container').remove();
                    }
                });
            }
        },

        updateMarkup: function(paginatedData) {
            var self = this;

            if (self.contentType === 'news') {
                self.updateNewsMarkup(paginatedData);
            } else if (self.contentType === 'events') {
                self.updateEventsMarkup(paginatedData);
            }
        },

        updateNewsMarkup: function(paginatedData) {
            var newsMarkup = '';
            
            for (var i = 0; i < paginatedData.NewsList.length; i++) {
                var hasImage = false;
                if (paginatedData.NewsList[i].Image)
                {
                    hasImage = true;
                }
                if (hasImage) {
                    newsMarkup += '<div class="news-listing"><div class="container"><div class="row middle-xs"><div class="col-12 col-md-4 col-lg-3 align-self-center">';
                    newsMarkup += '<div class="news-thumbnail"><a aria-hidden="true" href="' + paginatedData.NewsList[i].CTALink + '"><img src="' + paginatedData.NewsList[i].Image + '" alt="' + paginatedData.NewsList[i].ImageAltText + '"></a></div></div>';
                    newsMarkup += '<div class="col-lg-6 col-md-8 align-self-center"><div class="news-content"><div class="news-date"><p>' + paginatedData.NewsList[i].Date + '</p></div><div class="news-heading"><h2><a href="' + paginatedData.NewsList[i].CTALink + '">' + paginatedData.NewsList[i].Title + '</a></h2></div>';
                    newsMarkup += '<div class="d-lg-none"><div class="news-cta"><a href="' + paginatedData.NewsList[i].CTALink + '" class="btn btn-primary" title="' + paginatedData.NewsList[i].CTAText + '" aria-label="' + paginatedData.NewsList[i].AriLabelName + '">' + paginatedData.NewsList[i].CTAText + '</a></div></div></div></div>';
                    newsMarkup += '<div class="col-md-3 d-none d-lg-block align-self-center"><div class="news-cta"><a href="' + paginatedData.NewsList[i].CTALink + '" class="btn btn-primary" title="' + paginatedData.NewsList[i].CTAText + '" aria-label="' + paginatedData.NewsList[i].AriLabelName + '">' + paginatedData.NewsList[i].CTAText + '</a></div></div></div></div></div>';
                }
                else {
                    newsMarkup += '<div class="news-listing"><div class="container"><div class="row middle-xs">';
                    newsMarkup += '<div class="col-lg-9 no-image align-self-center"><div class="news-content"><div class="news-date"><p>' + paginatedData.NewsList[i].Date + '</p></div><div class="news-heading"><h2><a href="' + paginatedData.NewsList[i].CTALink + '">' + paginatedData.NewsList[i].Title + '</a></h2></div>';
                    newsMarkup += '<div class="d-lg-none"><div class="news-cta"><a href="' + paginatedData.NewsList[i].CTALink + '" class="btn btn-primary" title="' + paginatedData.NewsList[i].CTAText + '" aria-label="' + paginatedData.NewsList[i].AriLabelName + '">' + paginatedData.NewsList[i].CTAText + '</a></div></div></div></div>';
                    newsMarkup += '<div class="col-md-3 d-none d-lg-block align-self-center"><div class="news-cta"><a href="' + paginatedData.NewsList[i].CTALink + '" class="btn btn-primary" title="' + paginatedData.NewsList[i].CTAText + '" aria-label="' + paginatedData.NewsList[i].AriLabelName + '">' + paginatedData.NewsList[i].CTAText + '</a></div></div></div></div></div>';
                }
                
            }

            $(".news-wrapper").append('<div aria-live="polite">' + newsMarkup + '</div>');
        },

        updateEventsMarkup: function(paginatedData) {
            var eventsMarkup = '';

            for(var i = 0; i < paginatedData.EventsList.length; i++) {
                eventsMarkup += '<div class="event-list middle-xs"><div class="container"><div class="row middle-xs"><div class="col-lg-3 col-md-4 align-self-center">';
                eventsMarkup += '<div class="event-list-thumbnail"><a aria-hidden="true" href=' + paginatedData.EventsList[i].CTALink + '>';
                eventsMarkup += '<img src="';
                if (paginatedData.EventsList[i].EventImage) {
                    eventsMarkup += encodeURIComponent(paginatedData.EventsList[i].EventImage);
                }
                eventsMarkup += '" alt="' + paginatedData.EventsList[i].EventImageAltText + '">';
                eventsMarkup += '</a></div></div>';
                eventsMarkup += '<div class="col-lg-6 col-md-8 align-self-center"><div class="event-list-content"><div class="event-list-date">';
                if (!paginatedData.EventsList[i].EndDate) {
                    eventsMarkup += '<p>' + paginatedData.EventsList[i].Date + '<span class="divider">' + paginatedData.EventsList[i].Time + '</span></p></div>';
                }
                else {
                    eventsMarkup += '<p>' + paginatedData.EventsList[i].Date + ' TO ' + paginatedData.EventsList[i].EndDate + '<span class="divider">' + paginatedData.EventsList[i].Time + '</span></p></div>';
                }
                eventsMarkup += '<div class="event-list-metacontent"><h3><a href=' + paginatedData.EventsList[i].CTALink + '>' + paginatedData.EventsList[i].EventName+'</a></h3><p>' + paginatedData.EventsList[i].Description + '</p></div>';
                eventsMarkup += '<div class="d-none d-md-block d-lg-none"><div class="event-list-cta"><a href=' + paginatedData.EventsList[i].CTALink + ' class="btn btn-primary" title="' + paginatedData.EventsList[i].CTAText + '" aria-label="' + paginatedData.EventsList[i].AriLabelName + '">' + paginatedData.EventsList[i].CTAText + '</a></div></div>';
                eventsMarkup += '<div class="event-list-address">';
                if (paginatedData.EventsList[i].HasBranchDetails) {
                    eventsMarkup += '<h5>' + paginatedData.EventsList[i].Branch.BranchName + '</h5>';
                    eventsMarkup += '<p>' + paginatedData.EventsList[i].Branch.Address1 + ' ' + paginatedData.EventsList[i].Branch.Address2 + '<span class="spacer"></span>' + paginatedData.EventsList[i].Branch.City + ', ' + paginatedData.EventsList[i].Branch.State + ' ' + paginatedData.EventsList[i].Branch.ZipCode + '</p>';
                }
                eventsMarkup += '</div ></div ></div > ';
                eventsMarkup += '<div class="col-sm-3 end-sm d-md-none d-lg-block align-self-center"><div class="event-list-cta"><a href=' + paginatedData.EventsList[i].CTALink + ' class="btn btn-primary" title="' + paginatedData.EventsList[i].CTAText+'" aria-label="' + paginatedData.EventsList[i].AriLabelName+'">' + paginatedData.EventsList[i].CTAText + '</a></div></div></div></div></div>';
            }

            $(".event-list-wrapper").append('<div aria-live="polite">' + eventsMarkup + '</div>');
            eventContentEllipsis();
        }
    };

    return {
        init: function(contentType, loadMoreLink) {
            var paginator = Object.create(fn);
            paginator.init(contentType, loadMoreLink);
            return paginator;
        }
    };
})();

if ($('.event-list-wrapper').length) {
    var eventPaginator = scrollPagination.init('events', '.events-load-more');
}

if ($('.news-wrapper').length) {
    var newsPaginator = scrollPagination.init('news', '.news-load-more');
}