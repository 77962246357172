var BlogsData, sortedList;
var BloglistedCount;
var FilteredBlogs = [];
var setBlogsPerpage;
var FilterOptions = { category: [], mediaType: [], sortBy: 'Newest' };
var filterState = false;
var sortState = false;
var MonthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var isMoreRelatedArticlesOpen = false;
$("#viewMoreBlogs").hide();
$(function () {
    $(document).ready(function () {
        $('#cat-btn').click(function () {
            $('#cat-popup').show();
            $('#filter-popup').hide();
        });
        $('.cat-collapse-btn').click(function () {
            $('#cat-popup').hide();
        });
        $('#filter-btn').click(function () {
            $('#filter-popup').show();
            $('#cat-popup').hide();
        });
        $('.filter-collapse-btn').click(function () {
            $('#filter-popup').hide();
        });
        setBlogsPerpage = $("#blogsperpage").val();
        var checkBlogPage = $(".blog-list-box");
        if (checkBlogPage.length > 0) {

            $.ajax({
                method: "get",
                url: '/api/BlogApi/GetBlogs',
                //data: '{"currentPage":0, "itemsPerPage" : 10, "category": "", "mediaType":"", "sortBy":0}',
                dataType: 'JSON',
                success: function (result) {
                    BlogsData = sortBlogs(result.Data.Blogs, 'Newest');
                    //console.log(BlogsData);
                    UpdateBlogs(BlogsData, setBlogsPerpage);
                    if (setBlogsPerpage < BlogsData.length) {
                        $("#viewMoreBlogs").show();
                    }
                },
                error: function (error) {
                    console.log(error.responseText);
                }
            });
        }
        $('#viewMoreBlogs').click(function () {
            BloglistedCount = BloglistedCount + setBlogsPerpage;
            if (filterState == true) {
                UpdateBlogs(FilteredBlogs, BloglistedCount);
            }
            else {
                UpdateBlogs(BlogsData, BloglistedCount);
            }
        });
        $('#copyBlogLinkToClipboard').click(function () {
            var $temp = $("<input>");
            var $url = $(location).attr('href');
            $("body").append($temp);
            $temp.val($url).select();
            document.execCommand("copy");
            $temp.remove();
            $(this).append('<div style="background:#FFCE34;padding:3px 5px;border-radius:2px;">Link Copied</div>');
            $(this).find('div').fadeOut(1000);
        })
        $('#viewMoreRelatedArticles').on('click', function () {
            if (isMoreRelatedArticlesOpen) {
                var articlesToHide = [];
                $('#related-articles').find('.article-snippet').each(function (index) {
                    if (index > 2) {
                        articlesToHide.push(this);
                    }
                });
                $.each(articlesToHide, function () {
                    $(this).addClass('d-none');
                });

                $('#viewMoreRelatedArticles').html('View More');
                isMoreRelatedArticlesOpen = false;
            }
            else {
                $('#related-articles').find('.article-snippet.d-none').removeClass('d-none');
                $('#viewMoreRelatedArticles').html('View Less');
                isMoreRelatedArticlesOpen = true;
            }
        });
        $("#page_back_btn").on('click', function () {
            window.history.go(-1);
        });
        $('.blogCatCheckbox').change(function () {
            var checkbox = $(this);
            //console.log(checkbox[0].checked);
            if (checkbox[0].checked == true) {
                if (FilterOptions.category.indexOf(checkbox[0].id) > -1) {
                    FilteringBlogs();
                } else {
                    FilterOptions.category.push(checkbox[0].id);
                    FilteringBlogs();
                }
            } else {
                if (FilterOptions.category.indexOf(checkbox[0].id) > -1) {
                    FilterOptions.category.splice(FilterOptions.category.indexOf(checkbox[0].id), 1);
                    FilteringBlogs();
                } else {
                    FilteringBlogs();
                }
            }

        });
        $('.blogMediaType').change(function () {
            var checkbox = $(this);
            console.log(checkbox[0].checked);
            if (checkbox[0].checked == true) {
                if (FilterOptions.mediaType.indexOf(checkbox[0].id) > -1) {
                    FilteringBlogs();
                } else {
                    FilterOptions.mediaType.push(checkbox[0].id);
                    FilteringBlogs();
                }
            } else {
                if (FilterOptions.mediaType.indexOf(checkbox[0].id) > -1) {
                    FilterOptions.mediaType.splice(FilterOptions.mediaType.indexOf(checkbox[0].id), 1);
                    FilteringBlogs();
                } else {
                    FilteringBlogs();
                }
            }

        });
        $('.blogsSortType').change(function () {
            var checkbox = $(this);
            sortState = true;
            FilterOptions.sortBy = checkbox[0].value;
            FilteringBlogs();
        });

    });
});
$(document).on('click', '.video', function () {

});
$(document).on("click", ".closeBtn", function () {
    $.magnificPopup.close();
});
function UpdateBlogs(data, blogsPerPage) {
    var blogs = data;
    var blogsPerPage = blogsPerPage;
    BloglistedCount = blogsPerPage;
    var blogsCount;
    if (data.length <= blogsPerPage) { blogsCount = data.length; $('#viewMoreBlogs').hide(); } else { blogsCount = blogsPerPage; $('#viewMoreBlogs').show(); };
    var blogsHtmlContent = '';
    var align = 'left';
    if (data.length == 0) {
        blogsHtmlContent = '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"><div class="noblogs"><p>No Blogs Found!</p></div></div>';
    }
    else {

        for (var i = 0; i < blogsCount; i++) {
            var blogUrlArray = blogs[i].Url.split('/');
            var blogUrl = "/blog/" + blogUrlArray[blogUrlArray.length - 1];

            var dateKey = MonthsList[new Date(blogs[i].BlogDate).getMonth()] + ' ' + new Date(blogs[i].BlogDate).getDate() + ', ' + new Date(blogs[i].BlogDate).getFullYear();
            blogsHtmlContent += '<div class="col-xs-12 col-sm-6 col-md-6 col-lg-4"><div class="' + align + '-align" ><div class="blog-item">';
            blogsHtmlContent += '<div class="blog-img"><img src="' + blogs[i].ThumbnailImage + '" alt="">';
            if (blogs[i].MediaType != '2E859331AC0E4F42BCF3D62F0A0E611C') {
                blogsHtmlContent += '<div class="hover-show"><a href="#' + blogs[i].ID.split("-").join("") + '" class="video" aria-label="Play"><img src="../assets/images/icon-play-white.svg" /><br><span>Preview</span></a></div>';
            }
            blogsHtmlContent += '</div>';
            blogsHtmlContent += '<div class="blog-details"><p>' + blogs[i].CategoryNames.split('|').join(', ') + '</p><h2 class="h3">' + blogs[i].Title + '</h2><article>' + blogs[i].Description + '</article>';
            blogsHtmlContent += '<div class="blog-bottom"><label for=""><b>' + blogs[i].MediaTypeName + ' |</b> ' + dateKey + '</label><a class="btn btn-primary" href="' + blogUrl + '">' + blogs[i].BlogCtaButtonText +'</a></div>';
            blogsHtmlContent += '</div></div></div></div>';
            //blog popup block start
            if (blogs[i].MediaType == '89C8EF1AEAC34A40BF93DCADF8A4805B') {
                blogsHtmlContent += '<div id="' + blogs[i].ID.split("-").join("") + '" class="mfp-hide modal-video-player video-player"><div class="modal-video-wrapper"><video data-videoid="' + blogs[i].VideoItem.ShortID + '" class="videoPlayerDuration" preload="none" poster="" controls crossorigin>';
                blogsHtmlContent += '<source tabindex="-1" src="' + blogs[i].VideoItem.Mp4Source + '" type="video/mp4"><source tabindex="-1" src="" type="video/webm"><track kind="captions" label="English" srclang="en" src="" default>';
                blogsHtmlContent += '<a href="' + blogs[i].VideoItem.Mp4Source + '" download>Download</a></video><a href="' + blogs[i].VideoItem.TranscriptLinkSource + '" class="transcript" aria-label="' + blogs[i].VideoItem.Title + '" title="' + blogs[i].VideoItem.Title + '">';
                blogsHtmlContent += '<img src="' + blogs[i].VideoItem.TranscriptImage + '" alt="Play"></a></div><button type="button" class="closeBtn" aria-label="Close Popup" title="Close Popup"><img src="/assets/images/icon-close-gray.png" alt="Close Popup"></button>';
                blogsHtmlContent += '</div>';
            } else if (blogs[i].MediaType == '230C2277EF3B4B8C83585C3FB827359B') {
                blogsHtmlContent += '<div id="' + blogs[i].ID.split("-").join("") + '" class="mfp-hide modal-video-player video-player">';
                blogsHtmlContent += '<div class="m7-card-info pb-4"><div class="m7-card-icon"><a><img src="' + blogs[i].PodcastItem.ThumbnailImage + '"/></a></div><div class="m7-card-desc"><div class="m7-card-type">Podcast</div><h3 class="h4">' + blogs[i].PodcastItem.Title + '</h4>' + blogs[i].PodcastItem.Description + '</div></div>';
                blogsHtmlContent += '<div class="modal-video-wrapper"><div class="audio-player">';
                blogsHtmlContent += '<audio crossorigin="" class="plyr--setup" preload="none"><source src="' + blogs[i].PodcastItem.Mp3Source + '" type="audio/mp3"><source src="" type="audio/ogg"></audio>';
                blogsHtmlContent += '<a href="' + blogs[i].PodcastItem.TranscriptLinkSource + '" style="right:0;top:0;bottom:0;" class="transcript" aria-label="' + blogs[i].PodcastItem.Title + '"><img src="' + blogs[i].PodcastItem.TranscriptImage + '" alt="">';
                blogsHtmlContent += '</a></div></div><button type="button" class="closeBtn" aria-label="Close Popup" title="Close Popup"><img src="/assets/images/icon-close-gray.png" alt="Close Popup"></button></div>';
            }


            //blog popup block end
            if (align == 'left') {
                align = 'center';
            } else if (align == 'center') {
                align = 'right';
            } else if (align == 'right') {
                align = 'left';
            }
        }
    }
    console.log(data);
    $('#blogsloader').hide();
    $(".blog-list-box").html(blogsHtmlContent);
    $(".video").magnificPopup({
        type: "inline",
        midClick: true,
        fixedContentPos: false,
        callbacks: {
            open: function () {
                lastFocusedElement = document.activeElement;
                var elementID = $(lastFocusedElement).attr("href");
                $("html, body, main, .mfp-preloader, .skip-link").attr({
                    tabindex: -1,
                });
                $(elementID).find("video").attr("tabindex", 0).focus();
            },
            close: function () {
                $("html, body, main, .mfp-preloader, .skip-link").attr({
                    tabindex: "",
                });
                if (lastFocusedElement) {
                    $(lastFocusedElement).focus();
                }

                //Stop video play on change
                instances.forEach(function (instance) {
                    instance.pause();
                });
            },
        },
    });
    var instances;
    var videoPlay = function () {
        if ($(".video-player").length || $(".audio-player").length) {
            instances = plyr.setup({
                // Output to console
                //debug: true,
                hideControls: false,
                showPosterOnEnd: false,
            });

            var i = 1;
            instances.forEach(function (instance) {
                instance.on("ready", function (event) {
                    instance.getContainer().setAttribute("id", "plyId-" + i);
                    instance.plyId = "plyr-" + i;
                    i++;
                });
                instance.on("play", function (event) {
                    var currentPid = instance.plyId;
                    instances.forEach(function (instance) {
                        if (currentPid != instance.plyId) {
                            instance.pause();
                        }
                    });
                });
            });
        }
    };
    videoPlay();
    $(".popup").magnificPopup({
        type: "inline",
        midClick: true,
        fixedContentPos: false,
        overflowY: "scroll",
        callbacks: {
            open: function () {
                jQuery("body").addClass("noscroll"),
                    $("html, body, main, .mfp-preloader, .skip-link").attr({
                        tabindex: -1,
                    });
            },
            close: function () {
                jQuery("body").removeClass("noscroll"),
                    $("html, body, main, .mfp-preloader, .skip-link").attr({
                        tabindex: "",
                    });
            },
        },
    });
}
function FilteringBlogs() {
    console.log(FilterOptions);
    var blogsList = BlogsData;
    FilteredBlogs = [];
    if (FilterOptions.category.length > 0) {
        $.each(FilterOptions.category, function (e) {
            if (FilterOptions.mediaType.length > 0) {
                $.each(FilterOptions.mediaType, function (j) {
                    $.each(blogsList, function (i) {
                        if ((blogsList[i].Categories.indexOf(FilterOptions.category[e]) > -1) && (blogsList[i].MediaType.indexOf(FilterOptions.mediaType[j]) > -1)) {

                            if (!FilteredBlogs.some(function (e) { return e.ID == blogsList[i].ID })) {
                                FilteredBlogs.push(blogsList[i]);
                            }
                        }
                    });
                });
            }
            else {
                $.each(blogsList, function (i) {
                    if (blogsList[i].Categories.indexOf(FilterOptions.category[e]) > -1) {
                        if (!FilteredBlogs.some(function (e) { return e.ID == blogsList[i].ID })) {
                            FilteredBlogs.push(blogsList[i]);
                        }
                    }
                });
            }
        });

        filterState = true;
    }
    else if (FilterOptions.mediaType.length > 0) {
        $.each(FilterOptions.mediaType, function (j) {
            $.each(blogsList, function (i) {
                if (blogsList[i].MediaType.indexOf(FilterOptions.mediaType[j]) > -1) {
                    if (!FilteredBlogs.some(function (e) { return e.ID == blogsList[i].ID })) {
                        FilteredBlogs.push(blogsList[i]);
                    }
                }
            });
        });
        filterState = true;
    }
    else {
        filterState = false;
    }

    if (filterState == true) {
        sortBlogs(FilteredBlogs, FilterOptions.sortBy);
        UpdateBlogs(FilteredBlogs, BloglistedCount);
    }
    else {
        if (sortState == true) {
            UpdateBlogs(sortBlogs(BlogsData, FilterOptions.sortBy), BloglistedCount);
        }
        else {
            UpdateBlogs(blogsList, BloglistedCount);
        }
    }
    console.log(FilteredBlogs);

}
function sortBlogs(data, type) {
    if (type == "Newest") {
        sortedList = data.sort(function (a, b) {
            var dateA = new Date(a.BlogDate);
            var dateB = new Date(b.BlogDate);
            return dateB - dateA;
        });
        console.log(sortedList);
        return sortedList;
    }
    else if (type == "MostPopular") {
        sortedList = data.sort(function (a, b) {
            return b.PopularScore - a.PopularScore;
        });
        console.log(sortedList);
        return sortedList;
    }
    else if (type == "Featured") {
        sortedList = data.sort(function (a, b) {
            return b.Featured - a.Featured;
        });
        console.log(sortedList);
        return sortedList;
    };
}

